@import "./sass/ladda";
@import "./sass/variables";
@import "./sass/mixins";
@import "./sass/utilities";
@import "./sass/custom";

// @font-face {font-family: 'Georgia-Regular';src: url('http://localhost:3000/fonts/georgia.eot');src: url('http://localhost:3000/fonts/Georgia.woff') format('woff'), url('http://localhost:3000/fonts/Georgia.ttf') format('truetype'), url('http://localhost:3000/fonts/Georgia.svg') format('svg');font-weight: normal;font-style: normal;}
@font-face {font-family: 'Georgia-Bold';src: url('images/fonts/georgiab.eot');src: url('images/fonts/Georgia-Bold.woff') format('woff'), url('images/fonts/Georgia-Bold.ttf') format('truetype'), url('images/fonts/Georgia-Bold.svg') format('svg');font-weight: normal;font-style: normal;}
@font-face {font-family: 'Gilroy-Black';src: url('images/fonts/Gilroy-Black.eot');src: url('images/fonts/Gilroy-Black.woff') format('woff'), url('images/fonts/Gilroy-Black.ttf') format('truetype'), url('images/fonts/Gilroy-Black.svg') format('svg');font-weight: normal;font-style: normal;}


body { font-family: 'Poppins', sans-serif !important; line-height: 26px; background-color: transparent !important; overflow-x: hidden;} 
a:hover {outline: none;transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out;}
button:focus {outline: none;}
.all-content {@include full-width(); min-height: calc(50vh - 24px); padding-top: 0px;
  // @media (max-width: 991px) {padding-top: 66px;}
  // @media(max-width: 767px) {min-height: calc(23vh - 18px);}
  // @media (max-width: 360px) {padding-top: 107px;}
}
.auth-all-content {@include full-width(); min-height: calc(100vh - 0px);}
/// Css apply only mizilla firefox
@-moz-document url-prefix() {
	.all-content {@include full-width(); min-height: calc(65vh - 15px);}
} // Css Apply only 
.inner-page {@include full-width(); padding: 30px 0px; }
//@media (min-width: 1170px) {.container {max-width: 1200px;}}
@media (min-width: 1199px) { .container-fluid { max-width: 1720px;} .container {max-width: 1200px;}}
.react-confirm-alert-body > h1 {font-size: 26px; font-weight: 600;}
.react-confirm-alert-overlay {z-index: 1309 !important;}
.react-confirm-alert-body {text-align: center;}
.app-loading {background-color: $primary;}
.app-loading {position: fixed; left: 0px; top: 0px; display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%; width: 100%; float: left; z-index: 99;}
.loading {position: relative; z-index: 2;  width: 100px; height: 100px;}
.valid-feedback {font-size: 12px; font-family: Arial, Helvetica, sans-serif; color: $primary !important;}
.viewallbtn {margin: 10px 0 0 0; text-align: right;
  a {font-size: 18px; font-weight: 600; text-transform: uppercase; text-decoration: underline; color: $primary;}
}

/// PopUp CSS Start
.MuiDialog-root.bookcoach {
  .MuiTypography-h6.text-primary {font-size: 22px; margin-bottom: 10px;}
  .est-current-time {font-size: 25px; text-transform: uppercase; font-weight: 700; text-align: center; color: $primary; margin-bottom: 15px;}
  .MuiIconButton-root.closeButton {margin: -85px 0 0 0 !important;}
}
.MuiDialog-root.bookcoach.buypackage {
  .MuiIconButton-root.closeButton {margin: -38px 0 0 0 !important;}
}
.MuiDialog-root.zipcode-dialog.writeareview .MuiPaper-root, .MuiDialog-root.zipcode-dialog.WelcomeDialog .MuiPaper-root {min-width: 970px;}
.MuiDialog-root.zipcode-dialog.WelcomeDialog .MuiPaper-root .welcometxt {font-size: 15px; color: #393939; line-height: 28px;}
.MuiDialog-root.zipcode-dialog.WelcomeDialog .MuiPaper-root .welcometxt p {margin-bottom: 25px;}
.MuiDialog-root.zipcode-dialog.WelcomeDialog .MuiPaper-root .welcometxt p.semibold {font-weight: 600; text-transform: uppercase;}
.MuiDialog-root.zipcode-dialog.WelcomeDialog .MuiPaper-root .welcometxt a {font-weight: 700; text-decoration: underline;}
.MuiPaper-root.MuiDialog-paperScrollBody, .MuiDialog-root.zipcode-dialog .MuiPaper-root {min-width: 660px; border-radius: 8px !important;
  .MuiIconButton-root {float: right; padding: 0px; color: $gray-900; font-size: 18px; background: none; border: 1px solid $gray-900; width: 30px; height: 30px; margin: -35px 0 0 0;}
  .MuiDialogTitle-root {padding: 20px 30px 16px; background: $white !important; border-bottom: 1px solid #CCCCCC;}
  .MuiTypography-h6 {font-size: 25px; font-weight: 600; color: $gray-900; font-family: 'Poppins', sans-serif !important; text-align: center;}
  .MuiDialogContent-root {padding: 30px 40px 5px 40px;}
  label {font-size: 15px; color: $gray-900; padding-left: 10px; font-family: 'Poppins', sans-serif !important;}
  .form-control {font-size: 15px;border: 0px solid #cbcbcb;height: 50px;border-radius: 30px !important;box-shadow: 0 0 6px #cfcfcf; color: #393939; padding-left: 25px; font-weight: 600; text-transform: uppercase;}
  .StripeElement {font-size: 15px;border: 0px solid #cbcbcb;height: 50px;border-radius: 30px;box-shadow: 0 0 6px #cfcfcf; color: #393939; padding: 18px 0 0 25px; font-weight: 600; text-transform: uppercase;}
  textarea.form-control {height: 142px; border-radius: 17px; resize: none;}
  .form-group {margin-bottom: 35px;}
  .btn-primary {color: #ffffff;background-color: $primary;border-color: $primary; padding: 0 58px; text-decoration: none !important; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none;}
  .btn-primary:hover {color: $white; background-color: $secondary;border-color: $secondary;}
  .btn-secondary {color: #ffffff;background-color: $primary;border-color: $primary; padding: 0 58px; text-decoration: none !important; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none;}
  .btn-secondary:hover {color: $white; background-color: $secondary;border-color: $secondary;}
  .btn-info {color: #ffffff;background-color: $secondary;border-color: $secondary; padding: 0 43px; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none; margin: 0 0 0 15px;}
  .btn-info:hover {color: $white; background-color: $primary;border-color: $primary;}
  .select {position: relative;background: white; border-radius: 30px; box-shadow: 0 0 6px #cfcfcf;}
    .select:after {content: '';position: absolute;top: -2px;width: 0;height: 0;right: 25px;bottom: 0;margin: auto;border-style: solid;border-width: 6px 6px 0 6px;border-color: #212121 transparent transparent transparent;pointer-events: none;}
    .select:before {width: 30px;position: absolute;top: 1px;right: 1px;bottom: 1px;background: none;content: '';pointer-events: none;}
    .select:hover:before {background: none;}
    .select select {font-size: 15px;font-weight: 600;border: none;box-shadow: none;border-radius: 30px;background: #ffffff;height: 100%;width: 100%;cursor: pointer;outline: none;padding-right: 35px;padding-left: 25px;border: 0px solid #CBCBCB;-moz-appearance: none;-webkit-appearance: none;color: #212121 !important;height: 50px;}
    .select select:-moz-focusring {color: transparent;}
    .select select::-ms-expand {display: none;}
  .slots {
    .badge-secondary {background-color: #E1F2FE; color: #393939; font-size: 14px; font-weight: 400; width: 180px; height: 40px; border-radius: 20px; line-height: 33px; margin: 0 13px 15px 0;}
    li.active .badge-secondary {background-color: $primary; color: $white;}
  }  
  .form-control {
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {padding: 0px; border: none;}
    .MuiOutlinedInput-notchedOutline {border: none;}
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {color: #393939;padding-left: 0px;font-family: 'Poppins', sans-serif !important;text-transform: uppercase;font-size: 15px;font-weight: 600;}
    .MuiAutocomplete-endAdornment .MuiIconButton-root {color: $gray-900 !important;}
  }
}

.MuiDialog-root.bookcoach .MuiPaper-root .MuiDialogContent-root {
  .MuiIconButton-root {color: $primary !important;}
  .MuiIconButton-root {float: none; padding: 0px; color: $gray-900; font-size: 18px; background: none; border: 0px solid $gray-900; width: 30px; height: 30px; margin: 0px 0 0 0;}
  .MuiFormControlLabel-root {margin-left: -15px;}
  .MuiTypography-body1 {font-size: 15px; font-weight: 600; font-family: 'Poppins', sans-serif !important; padding-left: 10px;}
  .form-control {
    .MuiAutocomplete-endAdornment .MuiIconButton-root {color: #212121 !important;}
    .MuiSvgIcon-root {font-size: 29px;}
  }
}
.react-datepicker {font-family: 'Poppins', sans-serif !important; font-size: 16px; font-weight: 600;}
.datepicker .react-datepicker .react-datepicker__header .react-datepicker__current-month {background-color: $secondary; padding: 18px; font-size: 20px; font-weight: 600;}
.react-datepicker .react-datepicker__navigation {border: 0.70rem solid transparent; top: 20px;}
.react-datepicker .react-datepicker__navigation--previous {border-right-color: $white;}
.react-datepicker .react-datepicker__navigation--next {border-left-color: $white;}
.datepicker .react-datepicker .react-datepicker__day--selected, .datepicker .react-datepicker .react-datepicker__day--in-selecting-range, .datepicker .react-datepicker .react-datepicker__day--in-range, .datepicker .react-datepicker .react-datepicker__month-text--selected, .datepicker .react-datepicker .react-datepicker__month-text--in-selecting-range, .datepicker .react-datepicker .react-datepicker__month-text--in-range, .datepicker .react-datepicker .react-datepicker__quarter-text--selected, .datepicker .react-datepicker .react-datepicker__quarter-text--in-selecting-range, .datepicker .react-datepicker .react-datepicker__quarter-text--in-range {background: $primary; color: $white;}
/// PopUp CSS End

.homeheader header {width: 100%;float: left;position: relative;box-shadow: none !important;z-index: 1; background-color: transparent !important; background-image: linear-gradient(to bottom, black, transparent) !important;
  .navbar-brand {
    img.innerlogo {display: none;}
    img.homelogo {display: block;}
  }
}
.homeheader header .navbar {padding-top: 0px; padding-bottom: 0px; background: none !important;}
.homeheader header .navbar .navbar-nav .nav-item .nav-link {text-transform: uppercase;color: $white;font-size: 15px;line-height: 17px;padding-right: 15px;padding-left: 15px; font-weight: 600;}
.homeheader header .navbar .navbar-nav .nav-item .nav-link:hover {color: $primary;}
.homeheader header .navbar .right-menus .headbtn {padding: 0px;
  .btn-secondary {color: #ffffff;background-color: transparent;border-color: $white; padding: 0 18px; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600;}
  .btn-secondary:hover {color: #ffffff;background-color: #2E2E2E;border-color: #2E2E2E;}
  .btn-primary {color: #ffffff;background-color: $primary;border-color: $primary; padding: 0 18px; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; margin: 0 20px 0 0;}
  .btn-primary:hover {color: #ffffff;background-color: #2E2E2E;border-color: #2E2E2E;}
}
.homeheader header {
  .navbar-profile {
    .ic-black {display: none;}
    .ic-white {display: inline-block;}
  }
}
header {box-shadow: 0 0 10px #bebebe !important;
  .navbar-brand {
    img.homelogo {display: none;}
  }
  .navbar-profile {
    .ic-white {display: none;}
    .notify {padding-right: 15px; padding-left: 0px;}
  }
  .badge-primary {width: 20px;height: 20px;padding: 0;line-height: 20px;margin: -5px 0 0 -15px;position: absolute}
}
header .navbar {padding-top: 0px; padding-bottom: 0px;}
header .container {max-width: 1600px;}
header .navbar .navbar-nav .nav-item .nav-link {text-transform: uppercase;color: $gray-900;font-size: 15px;line-height: 17px;padding-right: 15px;padding-left: 15px; font-weight: 600;}
header .navbar .navbar-nav .nav-item .nav-link:hover {color: $primary;}
header .navbar .navbar-nav .nav-item .nav-link.dropdown-toggle:after {vertical-align: middle;border-top: 7px solid;border-right: 8px solid transparent;border-bottom: 0;border-left: 7px solid transparent; margin: -5px 0 0 8px;}
header .navbar .navbar-nav .nav-item .nav-link.dropdown-toggle {padding: 0px;
  img {width: 45px; height: 45px; border-radius: 100%; object-fit: cover; object-position: center; margin: 0 15px 0 0;}
}
header .navbar .right-menus .headbtn {padding: 0px;
  .btn-secondary {color: $gray-900;background-color: transparent;border-color: $gray-900; padding: 0 18px; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600;}
  .btn-secondary:hover {color: #ffffff;background-color: #2E2E2E;border-color: #2E2E2E;}
  .btn-primary {color: #ffffff;background-color: $primary;border-color: $primary; padding: 0 18px; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; margin: 0 20px 0 0;}
  .btn-primary:hover {color: #ffffff;background-color: #2E2E2E;border-color: #2E2E2E;}
}
@media (max-width: 1400px) {header .navbar {margin: 0; }}

.homebanner {min-height: 1080px; background: #fff url("images/homebanner.png") top center no-repeat;background-size: 100%; margin: -132px 0 0;
  .container {max-width: 1340px;}
  h1 {font-family: 'Gilroy-Black';font-size: 100px; text-transform: uppercase; color: $white; margin: 0 0 5px 0; padding: 415px 0 0 0; text-align: center;}
  .content {font-size: 40px; font-weight: 700; color: $white; text-align: center; margin: 0 0 160px 0;}
  .content-two {font-size: 45px; font-weight: 700; color: $white; text-align: center; margin: 0 0 15px 0;}
  .content-three {font-size: 55px; font-weight: 700; color: #FFD203; text-align: center; margin: 0 0 22px 0; text-transform: uppercase;}
  .bannerbtn {
    ul {list-style: none; padding: 0; margin: 0px; text-align: center;
      li {display: inline-block; width: 15%; margin: 0 0px 30px  0;}
      //li:nth-child(4) {margin: 0 0 0 0;}
      //li:nth-child(2) .btn-primary, li:nth-child(3) .btn-primary {padding: 33px 0px;}
    }
    .btn-primary {color: #ffffff;background-color: $primary;border-color: $primary; padding: 0px 0px; line-height: 53px; border-radius: 50px; font-size: 18px; text-transform: capitalize; font-weight: 700; width: 100%; box-shadow: none;
      span {font-size: 15px; text-transform: initial; font-weight: 600;}
    }
    .btn-primary:hover {color: #ffffff;background-color: $secondary;border-color: $secondary;}
  }
  .downarrow {font-size: 50px; font-weight: 400; color: $white; text-align: center; margin: 0 0 0 0; line-height: 18px;
    a {cursor: pointer;
      i {line-height: 30px;}
    }
  }
  .bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
}

.hometheworld {background: $white; padding: 90px 0;
  h1 {font-size: 32px; font-weight: 700; color: $gray-900; margin: 0 0 75px 0; text-align: center; line-height: 48px;
    span {color: $primary;}
  }
  .twbox {
    .image {text-align: center;}
    h3 {font-size: 25px; font-weight: 600; color: $gray-900; margin: 35px 0 0px 0; text-align: center; line-height: 35px; padding: 0 30px;}
  }
}

.homeprofessional {background: $primary; padding: 80px 0;
  .container {max-width: 1206px;}
  h1 {font-size: 50px; font-weight: 700; color: $white; margin: 0 0 55px 0; text-align: center;}
  .image {text-align: center;
    img {width: 165px; height: 165px; border: 5px solid $white; border-radius: 100%;}
  }
  .content {font-size: 24px; color: $white; line-height: 54px; text-align: center; margin: 66px 0 0 0;
    .selectlink {font-size: 35px; font-weight: 700; color: $gray-900; text-decoration: underline; display: block; text-align: center; margin-top: 75px;}
    .selectlink:hover {color: $white;}
  }
}

.homegetmatched {background: $white; padding: 100px 0 20px 0;
  .container {max-width: 1210px;}
  h1.maintitle {font-size: 50px; font-weight: 700; color: $gray-900; margin: 0 0 30px 0; text-align: center;}
  .content {font-size: 25px; color: $gray-900; text-align: center; margin: 0 0 45px 0; line-height: 44px;}
  .btndiv {text-align: center;
    .btn-primary {color: #ffffff;background-color: $primary;border-color: $primary; padding: 0 52px; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600;}
    .btn-primary:hover {color: #ffffff;background-color: $secondary;border-color: $secondary;}
  }
  .borderdiv {border-top: 1px solid #B9BDBF; border-bottom: 1px solid #B9BDBF; height: 5px; margin: 80px 0 80px 0;}
  .communicatediv {
    h1.subtitle {font-size: 50px; font-weight: 700; color: $gray-900; margin: 0 0 45px 0; text-align: center;}
    .comcontent {
      h2 {font-size: 25px; font-weight: 600; color: $gray-900; margin: 0 0 65px 0; text-align: center;
        span {color: $primary; font-size: 75px; line-height: 0; margin: 0 24px;}
      }
    }
    .image {text-align: center; margin: 0 0 60px 0;}
    h3 {font-size: 35px; font-weight: 700; color: $secondary; margin: 0 0 100px 0; text-align: center;}
  }
}

.homecoaching {background: $white; padding: 0px 0px 100px 0;
  h1 {font-size: 50px; font-weight: 700; color: $gray-900; margin: 0 0 80px 0; text-align: center;}
  .coachingbox {text-align: center;
    h3 {font-size: 25px;  color: $gray-900; margin: 0 0 40px 0; text-align: center; line-height: 44px; padding: 0 35px;}
  }
}

.homecompare {background: #E1F2FE; padding: 100px 0;
  h1 {font-size: 50px; font-weight: 700; color: $gray-900; margin: 0 0 80px 0; text-align: center;
   span.educoach {color: $primary;}
   span.classroom {font-family: 'Georgia-Bold'; font-style: italic;}
  }
  .educoachtab.nav.nav-tabs {display: block; text-align: right; border-bottom: none;
    .nav-item {display: inline-block;
      .nav-link {width: 280px; height: 208px; border-top-left-radius: 14px; border-top-right-radius: 14px; border: none; background: $gray-900; color: $white; font-size: 30px; font-weight: 700; line-height: 46px; padding: 50px 0 0 0; text-align: center; display: inline-block; vertical-align: top;
        img {width: 223px;}
      }
      .nav-link.active {background: $primary; color: $white;}
    }
  }
  .educoachtab-content.tab-content {margin: 1px 0 0px 0;}
  .educoachtable {
    .edurow {background: $white; position: relative;
      .countnum {background: $white; width: 76px; height: 76px; position: absolute; border-radius: 100%; font-size: 35px; font-weight: 600; color: $gray-900; text-align: center; line-height: 76px; left: -40px; top: 0;}
      .educol-1 {display: inline-block; width: 610px; padding: 20px 0 20px 50px; font-size: 25px; font-weight: 600; color: #393939;}
      .educol-2 {display: inline-block; width: 280px; padding: 20px 0; text-align: center; border-left: 1px solid #DADADA; font-size: 25px; font-weight: 600; color: #95C11F;}
      .educol-3 {display: inline-block; width: 280px; padding: 20px 0; text-align: center; border-left: 1px solid #DADADA; font-size: 25px; font-weight: 600; color: #95C11F;}
      img {width: 32px;}
    }
    .edurow:nth-child(odd) {background: #EDEDED;}
    .edurow:nth-child(even) {
      .countnum {background: #EDEDED;}
    }
  }
}

.hometestimonials {background: $white; padding: 100px 0px;
  h1 {font-size: 35px; font-weight: 700; color: $gray-900; margin: 0 0 10px 0;}
  h2 {font-size: 50px; font-weight: 700; color: $secondary; margin: 0 0 20px 0; line-height: 76px;
    span {color: $primary; font-weight: 400;}
  }
  .content {font-size: 25px; line-height: 43px; color: $gray-900;}
  .slick-slider {box-shadow: 0 0 49px #e0e0e0; border-radius: 4px; padding: 40px 30px 20px 30px;}
  .slick-vertical .slick-slide {height: 300px !important;}
  .testibox {margin: 0 0 30px 0;
    h3 {font-size: 30px; font-weight: 600; color: $gray-900; margin: 0 0 20px 0;}
    .testcontent {font-size: 20px; color: $gray-900; font-style: italic; line-height: 35px; margin: 0 0 30px 0;
      b {font-weight: normal; font-weight: 600;}
    }
    .detail {
      img {display: inline-block; width: 66px; height: 66px; object-fit: cover; object-position: center; margin: 0 35px 0px 0; display: none;}
      .name {font-size: 20px; color: $gray-900; font-weight: 600;}
    }
  }
}

.homewereach {background: #E1F2FE; padding: 100px 0 70px 0;
  .container {max-width: 1210px;}
  h1 {font-size: 50px; font-weight: 700; color: $gray-900; margin: 0 0 42px 0; text-align: center;}
  h2 {font-size: 35px; font-weight: 700; color: $secondary; margin: 0 0 70px 0; text-align: center; line-height: 70px;}
  .col-sm-3:nth-child(4) {padding-top: 20px;}
  .col-sm-3:nth-child(5) {padding-top: 6px;}
  .col-sm-3:nth-child(8) {padding-top: 10px;}
  .col-sm-3:nth-child(10) {padding-top: 16px;}
  .col-sm-3:nth-child(12) {padding-top: 6px;}
  .col-sm-3:nth-child(14) {padding-top: 20px;}
  .col-sm-3:nth-child(15) {padding-top: 20px;}
  .col-sm-3:nth-child(18) {padding-top: 26px;}
  .col-sm-3:nth-child(19) {padding-top: 22px;}
  .col-sm-3:nth-child(20) {padding-top: 39px;}
  .wrbox {text-align: center; margin-bottom: 100px;
    .image {text-align: center; margin: 0 0 25px 0;}
    h3 {font-size: 25px; font-weight: 500;  color: $gray-900; margin: 0 0 0px 0; text-align: center; line-height: 42px; padding: 0 0px;}
  }
  .muchmore {font-size: 25px; font-weight: 700; color: $secondary; text-align: center; margin-top: -40px;}
}

.homeimproving {background: $white; padding: 0px 0px 80px;
  h1 {font-size: 50px; font-weight: 700; color: $gray-900; margin: 0 0 80px 0; text-align: center; line-height: 76px; padding: 0 190px;}
  .imrbox {
    .image {text-align: center; margin: 0 0 15px 0;}
  }
  h3 {font-size: 25px; font-weight: 700; color: $gray-900; margin: 0 0 15px 0; text-align: center; padding: 0 0px;}
  .content {font-size: 20px; color: #7D7C7C; line-height: 28px; text-align: center;}
  .homefaqs {padding: 120px 0 0 0; max-width: 770px; margin: 0 auto;
    h1 {font-size: 50px; font-weight: 700; color: $gray-900; margin: 0 0 50px 0; text-align: center; padding: 0;}
    .accordion {
      .card {margin: 0 0 20px 0; border: 1px solid #FBE6E5; border-radius: 4px;
        .card-header {margin-bottom: 0px; padding: 8px 20px; background-color: #FBE6E5; border-bottom: 1px solid #FBE6E5; border-radius: 0px;
          .btn-link {padding: 0; font-size: 18px; color: #393939; text-decoration: none; box-shadow: none;
            h4 {font-size: 18px; color: #393939; margin: 0; font-weight: 400;
              span {float: right; border: 2px solid #000;width: 21px;height: 21px;text-align: center;font-size: 13px;border-radius: 100%;line-height: 17px;}
            }
          }
        }
        .card-body {padding: 15px 20px; font-size: 15px; color: #393939; line-height: 30px;}
      }
    }
  }
}
  
.footer {width: 100%;float: left;position: relative; padding: 40px 0; background-color: #2E2E2E;
  .footer-top {border-bottom: 1px solid #707070; padding: 0 0 50px 0; margin: 0 0 70px 0;
    .footer-logo {text-align: center; margin: 0 0 30px 0;
      img {width: 250px;}
    }
    .footer-content {font-size: 20px; color: $white; line-height: 35px; text-align: center; padding: 0 175px;}
    .followus {margin: 35px 0 0 0px; text-align: center;
      .social-icons {margin: 0;
        li {display: inline-block;margin-right: 20px; 
          a {font-size: 30px; line-height: 18px;}
          a:hover {color: $white;}
          a.facebook {color: #3C579C;}
          a.facebook:hover {color: $white;}
          a.twitter {color: #55ACEE;}
          a.twitter:hover {color: $white;}
          a.linkedin {color: #007AB9;}
          a.linkedin:hover {color: $white;}
          a.instagram {color: #E34C37;}
          a.instagram:hover {color: $white;}
        }
        li:last-child {margin-right: 0px;}
      }
    }
  } 
  .footer-bottom {
    .footermenu {margin: 0 0 0 -25px;
      h4 {font-size: 20px; font-weight: 700; color: $white; margin: 0 0 20px 0;}
      ul {list-style: none; margin: 0px; padding: 0px;
        li {margin: 0 0 15px 0;
          a {font-size: 15px; color: #878484;}
          a:hover {color: $primary;}
          i {font-size: 20px; color: $white; margin: 5px 20px 15px 0; float: left;}
        }
      }
    }
    /*.footermenu.one {
      ul {
        li:nth-child(3), li:nth-child(4), li:nth-child(6), li:nth-child(8) {display: none;}
      }
    }
    .footermenu.two {
      ul {
        li:nth-child(3), li:nth-child(5) {display: none;}
      }
    }*/
    .footercontact {
      li {font-size: 15px; color: #878484; line-height: 28px;}
      a.partnerlink {font-size: 18px; color: $white; text-decoration: none; font-weight: 700;}
      a.partnerlink:hover {color: $primary;}
    }
  }
  .copy-right {font-size: 15px; color: #636262; text-align: center; padding: 25px 0 0 0;
   i {color: #CCCCCC; font-size: 22px; vertical-align: middle; margin: 0 0 0 5px;}
  }
}

.applytocoach { padding: 0px 0 50px 0;
  .applytocoachbanner {margin: 0 0 50px 0;
    img {width: 100%;}
  }
  h2 {font-size: 25px; font-weight: 600; text-transform: uppercase; margin: 0 0 30px 0;}
  .content {font-size: 15px; line-height: 28px; color: #393939;
    img {margin: 0 14px 0 0; vertical-align: bottom;}
    ul {padding: 0 0 0 30px; margin: 0 0 0 0;
      li {list-style: url("images/awesome-check.svg"); padding: 0 0 0 5px; margin: 0 0 25px 0;}
    }
    .tohelp {border: 1px dashed #D5D4D4; box-shadow: 0 0 6px #D5D4D4; border-radius: 4px; padding: 20px; margin: 30px 0 30px 0;}
    .follows {margin: 0 0 10px 0;
      img {margin: 0 8px 0 0; vertical-align: sub;}
    }
    p {margin: 0 0 30px 0;}
    p.ultxt {margin: 0 0 10px 0;
      img {margin: 0 8px 0 0; vertical-align: sub;}
    }
    .thanktxt {font-weight: 600; text-decoration: none; color: $primary;}
    a.thanktxtun {font-weight: 400; text-decoration: underline; color: $primary;}
    a.linktxt {text-decoration: underline;}
  }
  .btndiv {margin: 35px 0 30px 0;
    .btn-primary {color: #ffffff;background-color: $primary;border-color: $primary; padding: 0 47px; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none; margin: 0 20px 0 0;}
    .btn-primary:hover {color: $white;background-color: $secondary;border-color: $secondary;}
    .semibold {font-weight: 600; text-decoration: underline;}
  }
}

.applytoschool { padding: 0px 0 50px 0;
  .applytoschoolbanner {margin: 0 0 50px 0;
    img {width: 100%;}
  }
  h2 {font-size: 25px; font-weight: 600; margin: 0 0 30px 0;
    img {margin: 0 25px 0 0;}
  }
  .content {font-size: 15px; line-height: 28px; color: #393939; margin: 0 0 30px 0;
    img {float: left; margin: 9px 25px 0 0;}
  }
  .content.one {
    img {margin: 0 25px 0 0;}
  }
  .btndiv {margin: 35px 0 30px 0;
    h4 {font-size: 15px; font-weight: 700; margin: 50px 0 15px 0;}
    .btn-primary {color: #ffffff;background-color: $primary;border-color: $primary; padding: 0 0px; padding-top: 13px;padding-bottom: 13px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none; margin: 0 25px 0 0; width: 233px;}
    .btn-primary:hover {color: $white;background-color: $secondary;border-color: $secondary;}
    .semibold {font-weight: 600; text-decoration: underline;}
  }
}

.about-page {
  .aboutbanner {min-height: 750px; background: #fff url("images/aboutbanner.png") center center no-repeat;background-size: cover; margin: -232px 0 0;
    .container {max-width: 1345px;}
    h1 {font-size: 30px; font-weight: 500; color: $white; margin: 0 0 70px 0; padding: 330px 0px 0 0px; text-align: center; line-height: 50px;}
    .content {font-size: 30px; font-weight: 500; color: $white; text-align: center; margin: 0 0 80px 0;}
    .btndiv {text-align: center;
      .btn-primary {color: #ffffff;background-color: $primary;border-color: $primary; padding: 0px 52px; line-height: 48px; border-radius: 50px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none;}
      .btn-primary:hover {color: #ffffff;background-color: $secondary;border-color: $secondary;}
    }
    .downarrow {font-size: 50px; font-weight: 400; color: $white; text-align: center; margin: 331px 0 0 0;}
    .bounce {
      -moz-animation: bounce 2s infinite;
      -webkit-animation: bounce 2s infinite;
      animation: bounce 2s infinite;
    }
    
    @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-30px);
      }
      60% {
        transform: translateY(-15px);
      }
    }
  }
  .aboutmc {padding: 80px 0px; background: $white;
    h2 {font-size: 50px; font-weight: 700; color: $gray-900; margin: 0 0 30px 0; padding: 0 0 0 0; text-align: center;}
    .content {font-size: 20px; line-height: 38px; color: $gray-900; text-align: center;
      h3 {font-size: 25px; font-weight: 700; color: $secondary; margin: 30px 0 0px 0; padding: 0 0 0 0; text-align: center;}
    }
  }
  .aboutourcoaches {background: #E1F2FE; padding: 100px 0;
    h1 {font-size: 50px; font-weight: 700; color: $gray-900; margin: 0 0 30px 0; text-align: center;}
    .content {font-size: 20px; line-height: 38px; color: $gray-900; text-align: center; margin: 0 0 30px 0;}
    .image {text-align: center; margin: 0 0 30px 0;
      img {width: 165px; height: 165px; border: 5px solid $white; border-radius: 100%;}
    }
    .name {font-size: 20px; color: $gray-900; text-align: center;}
  }
  .aboutstori {padding: 100px 0px 80px; background: $white;
    h1 {font-size: 50px; font-weight: 700; color: $gray-900; margin: 0 0 50px 0; padding: 0 0 0 0; text-align: center;}
    .image{
      img {border-radius: 20px;}
    }
    .content {font-size: 20px; line-height: 42px; color: $gray-900; font-style: italic; margin: 0 0 54px 0;}
    .name {font-size: 20px; font-weight: 600; color: $gray-900;}
  }
  .aboutpartner {padding: 50px 0 50px 0; background: #E1F2FE;
    h1 {font-size: 50px; font-weight: 700; color: $gray-900; margin: 0 0 40px 0; text-align: center;}
    .prbox {background: $white; border-radius: 4px;
      .primage {
        img {border-radius: 4px;}
      }
      .detail {padding: 30px 0 0 20px;
        h4 {font-size: 20px; font-weight: 600; color: $gray-900; margin: 0px 0 15px 0;}
        .desi {font-size: 15px; color: $gray-900; margin: 0px 0 20px 0;}
        .emailid {font-size: 15px; color: $gray-900; margin: 0px 0 20px 0;}
        .followus {margin: 48px 0 0 0px; text-align: left;
          .social-icons {margin: 0;
            li {display: inline-block;margin-right: 20px; 
              a {font-size: 30px; line-height: 18px;}
              a:hover {color: $white;}
              a.facebook {color: #3C579C;}
              a.facebook:hover {color: $gray-900;}
              a.twitter {color: #55ACEE;}
              a.twitter:hover {color: $gray-900;}
              a.linkedin {color: #007AB9;}
              a.linkedin:hover {color: $gray-900;}
              a.instagram {color: #E34C37;}
              a.instagram:hover {color: $gray-900;}
            }
            li:last-child {margin-right: 0px;}
          }
        }
      }
    }
  }
  .ourprices {
    h2 {font-size: 25px; color: $gray-900; margin: 0 0 30px 0; text-align: center;
      span {font-weight: 700; text-decoration: underline;}
    }
    h4.one {font-size: 20px; font-weight: 700; text-transform: uppercase; color: $primary; margin: 30px 0 15px 0; text-align: center;}
    h4.two {font-size: 20px; font-weight: 400; color: $gray-900; margin: 0 0 25px 0; text-align: center;}
    h4.three {font-size: 20px; font-weight: 700; color: $secondary; margin: 0 0 0px 0; text-align: center;}
    .plansection {background: $white; border-radius: 4px; max-width: 970px; margin: 0 auto;
      .planbox {width: 25%; border-right: 1px solid $gray-200; float: left;
        .title {font-size: 25px; font-weight: 600; color: #F303FF; text-align: center; padding: 20px 0;}
        .price {font-size: 25px; font-weight: 600; color: $gray-900; text-align: center; padding: 5px 0; background: #F4BCF6;}
        .session {font-size: 25px; font-weight: 600; color: $gray-900; text-align: center; padding: 20px 0;
          span {font-size: 15px;}
        }
      }
      .planbox.basic {
        .title {color: #0E9BFF;}
        .price {background: #C9E4F8;}
      }
      .planbox.bronze {
        .title {color: #CD7F32;}
        .price {background: #CC9660;}
      }
      .planbox.bronzegold {
        .title {color: #B88139;}
        .price {background: #DEA151;}
      }
      .planbox:last-child {border-right: 0px;}
      .allsession {width: 100%; border-top: 1px solid $gray-200; padding: 20px 0; text-align: center; font-size: 20px; color: $gray-900}
    }
  }
  .aboutoutcomes {padding: 100px 0px 120px; background: $white;
    h1 {font-size: 50px; font-weight: 700; color: $gray-900; margin: 0 0 40px 0; padding: 0 0 0 0; text-align: center;}
    .content {font-size: 25px; line-height: 50px; color: $gray-900; margin: 0 0 0px 0; text-align: center;}
  }
  .aboutleadership {padding: 80px 0px 50px; background: #E1F2FE;
    h1 {font-size: 50px; font-weight: 700; color: $gray-900; margin: -10px 0 15px 0; padding: 0 0 0 0;}
    .image{margin: 0px 0 0 0;
      img {border-radius: 8px;}
    }
    .desi {font-size: 20px; color: $gray-900; line-height: 42px; margin-bottom: 25px;
      span {font-weight: 600;}
    }
    .content {font-size: 15px; line-height: 24px; color: $gray-900; margin: 0 0 0px 0;
      p {margin-bottom: 25px;}
    }
  }
  .aboutadvisory {background: $white; padding: 80px 0 55px;
    h1 {font-size: 50px; font-weight: 700; color: $gray-900; margin: 0 0 50px 0; text-align: center;}
    .image {text-align: center; margin: 0 0 20px 0;}
    .name {font-size: 18px; color: $gray-900; text-align: center; margin-bottom: 25px;}
    .aboutpartner {margin: 100px 0 0 0;
      h1 {font-size: 50px; font-weight: 700; color: $gray-900; margin: 0 0 40px 0; text-align: center;}
      .prbox {background: #E1F2FE; border-radius: 4px;
        .primage {
          img {border-radius: 4px;}
        }
        .detail {padding: 30px 0 0 20px;
          h4 {font-size: 20px; font-weight: 600; color: $gray-900; margin: 0px 0 15px 0;}
          .desi {font-size: 15px; color: $gray-900; margin: 0px 0 20px 0;}
          .emailid {font-size: 15px; color: $gray-900; margin: 0px 0 20px 0;}
          .followus {margin: 48px 0 0 0px; text-align: left;
            .social-icons {margin: 0;
              li {display: inline-block;margin-right: 20px; 
                a {font-size: 30px; line-height: 18px;}
                a:hover {color: $white;}
                a.facebook {color: #3C579C;}
                a.facebook:hover {color: $gray-900;}
                a.twitter {color: #55ACEE;}
                a.twitter:hover {color: $gray-900;}
                a.linkedin {color: #007AB9;}
                a.linkedin:hover {color: $gray-900;}
                a.instagram {color: #E34C37;}
                a.instagram:hover {color: $gray-900;}
              }
              li:last-child {margin-right: 0px;}
            }
          }
        }
      }
    }
  }
  .aboutteams {padding: 100px 0px 100px; background: #E1F2FE;
    h1 {font-size: 50px; font-weight: 700; color: $gray-900; margin: 0 0 50px 0; text-align: center;}
    .content {font-size: 20px; line-height: 42px; color: $gray-900; margin: 0 0 0px 0; text-align: center; font-style: italic;}
  }
  .aboutresources {padding: 80px 0 50px 0; background: #E1F2FE;
    h1 {font-size: 50px; font-weight: 700; color: $gray-900; margin: 0 0 40px 0; text-align: center;}
    .prbox {background: $white; border-radius: 4px; margin-bottom: 30px;
      .primage {
        img {border-radius: 4px;}
      }
      .detail {padding: 30px 0 0 20px;
        h4 {font-size: 20px; font-weight: 600; color: $gray-900; margin: 0px 0 15px 0;}
        .desi {font-size: 15px; color: $gray-900; margin: 0px 0 20px 0;}
        .emailid {font-size: 13px; color: $gray-900; margin: 0px 0 20px 0;}
        .followus {margin: 48px 0 0 0px; text-align: left;
          .social-icons {margin: 0;
            li {display: inline-block;margin-right: 20px; 
              a {font-size: 30px; line-height: 18px;}
              a:hover {color: $white;}
              a.facebook {color: #3C579C;}
              a.facebook:hover {color: $gray-900;}
              a.twitter {color: #55ACEE;}
              a.twitter:hover {color: $gray-900;}
              a.linkedin {color: #007AB9;}
              a.linkedin:hover {color: $gray-900;}
              a.instagram {color: #E34C37;}
              a.instagram:hover {color: $gray-900;}
            }
            li:last-child {margin-right: 0px;}
          }
        }
      }
    }
  }
  .aboutreviews {padding: 80px 0px 90px; background: $white;
    h1 {font-size: 50px; font-weight: 700; color: $gray-900; margin: 0 0 50px 0; text-align: center;}
    .hometestimonials {padding: 0 0;
      .col-sm-5 {display: none;}
      .col-sm-7 {flex: 0 0 100%; max-width: 100%;}
    }
  }
}

.contact-page {background: -webkit-linear-gradient(0deg, #E1F2FE 63%, $primary 63%); padding: 100px 0;
  .contact-box {background: $white; box-shadow: 0 0 6px #bebebe !important; border-radius: 8px;
    .contact-form {padding: 40px 10px 40px 40px;
      h2 {font-size: 25px; font-weight: 600; color: $gray-900; margin: 0 0 15px 0;}
      .content {font-size: 15px; line-height: 28px; color: #393939; padding: 0 0px 25px 0;}
      label {font-size: 15px; color: $gray-900; padding-left: 10px;}
      .form-control {font-size: 15px;border: 0px solid #cbcbcb;height: 50px;border-radius: 30px;box-shadow: 0 0 6px #cfcfcf; color: #393939; padding-left: 25px; font-weight: 600;}
      textarea.form-control {height: 97px; border-radius: 17px; resize: none;}
      .select {position: relative;background: white; border-radius: 30px; box-shadow: 0 0 6px #cfcfcf;}
      .select:after {content: '';position: absolute;top: -2px;width: 0;height: 0;right: 25px;bottom: 0;margin: auto;border-style: solid;border-width: 6px 6px 0 6px;border-color: #212121 transparent transparent transparent;pointer-events: none;}
      .select:before {width: 30px;position: absolute;top: 1px;right: 1px;bottom: 1px;background: none;content: '';pointer-events: none;}
      .select:hover:before {background: none;}
      .select select {font-size: 15px;font-weight: 600;border: none;box-shadow: none;border-radius: 30px;background: #ffffff;height: 100%;width: 100%;cursor: pointer;outline: none;padding-right: 35px;padding-left: 25px;border: 0px solid #CBCBCB;-moz-appearance: none;-webkit-appearance: none;color: #212121 !important;height: 50px;}
      .select select:-moz-focusring {color: transparent;}
      .select select::-ms-expand {display: none;}
      .form-group {margin-bottom: 25px;}
      .btn-primary {color: #ffffff;background-color: $primary;border-color: $primary; padding: 0 73px; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none;}
      .btn-primary:hover {color: $white; background-color: $secondary;border-color: $secondary;}
    }
    .contact-detail {background: $secondary; border-radius: 8px; padding: 40px 30px 40px 30px; height: 100%;
      h2 {font-size: 25px; font-weight: 600; color: $white; margin: 0 0 30px 0;}
      .info {
        ul {list-style: none;margin: 0px;padding: 0px;
          li {margin: 0 0 25px 0;font-size: 15px;color: $white; line-height: 28px; padding: 0 100px 0 0;
            a {font-size: 15px;color: $white;}
            i {font-size: 20px;color: #ffffff;margin: 5px 20px 15px 0;float: left;}
          }
        }
      }
      .followus {margin: 30px 0 0 0px; text-align: left;
        h5 {font-size: 15px; text-transform: uppercase; color: $white; font-weight: 600; margin: 0 0 22px 0;}
        .social-icons {margin: 0;
          li {display: inline-block;margin-right: 20px; 
            a {font-size: 30px; line-height: 18px; color: $white;}
            a:hover {color: $primary;}
          }
          li:last-child {margin-right: 0px;}
        }
      }
    }
  }
}

.faqpage {padding: 80px 0 50px 0; background: #E1F2FE;
  .faq-left {background: $white; box-shadow: 0 0 6px #d6d6d6; border-radius: 8px;
    .fl-header {font-size: 25px; font-weight: 600; color: $gray-900; margin: 0 0 0 0; text-align: center; padding: 30px 0; border-bottom: 1px solid #D5D4D4;}
    .fl-body {padding: 0px 30px 30px 30px;
      ul {padding: 0px; margin: 0px; list-style: none;
        li {margin: 30px 0 0px 0; text-align: center;
          a {font-size: 20px; color: #393939; text-transform: uppercase; font-weight: 600;}
        }
      }
    }
    .fl-footer {padding: 40px 0px; border-top: 1px solid #D5D4D4; text-align: center;
      h5 {font-size: 15px; font-weight: 600; color: #393939; text-transform: uppercase; margin: 0 0 30px 0;}
      .btndiv {text-align: center;
        .btn-primary {color: #ffffff;background-color: $primary;border-color: $primary; padding: 0 52px; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600;}
        .btn-primary:hover {color: #ffffff;background-color: $secondary;border-color: $secondary;}
      }
    }
  }
  .faq-right {background: $white; box-shadow: 0 0 6px #d6d6d6; border-radius: 8px;
    .fr-header {font-size: 25px; font-weight: 600; color: $gray-900; margin: 0 0 0 0; text-align: left; padding: 30px 30px; border-bottom: 0px solid #D5D4D4;}
    .fr-body {
      .accordion {
        .card {margin: 0 0 0px 0; border: 0px solid #FBE6E5; border-radius: 0px;
          .card-header {margin-bottom: 0px; padding: 20px 30px; background-color: $white; border-top: 1px solid #D5D4D4; border-bottom: 0px solid #D5D4D4; border-radius: 0px;
            .btn-link {padding: 0; font-size: 15px; font-weight: 600; color: #393939; text-decoration: none; box-shadow: none;
              h4 {font-size: 15px; font-weight: 600; color: #393939; margin: 0;
                span {float: right; font-size: 23px; margin: -7px 0 0 0;}
              }
            }
          }
          .card-body {padding: 15px 30px; font-size: 15px; color: #393939; line-height: 30px;}
        }
      }
    }
  }
}

.content-page {padding: 50px 0;
  h1.page-title {font-size: 30px; font-weight: 600; color: $gray-900; margin: 0 0 15px 0;}
  .contenttxt {font-size: 15px; line-height: 28px; color: #393939;
    img {width: 550px; height: auto; margin: 30px 0 0 0; border-radius: 10px;}
    h2 {font-size: 25px; font-weight: 600; color: $gray-900; margin: 0 0 30px 0; text-align: center;}
    strong {font-weight: 600; text-transform: uppercase;}
    p {margin: 0 0 30px 0;}
  }
}

.more-filter {
  .select {position: relative;background: white; border-radius: 30px; box-shadow: 0 0 6px #cfcfcf;}
  .select:after {content: '';position: absolute;top: -2px;width: 0;height: 0;right: 25px;bottom: 0;margin: auto;border-style: solid;border-width: 6px 6px 0 6px;border-color: #212121 transparent transparent transparent;pointer-events: none;}
  .select:before {width: 30px;position: absolute;top: 1px;right: 1px;bottom: 1px;background: none;content: '';pointer-events: none;}
  .select:hover:before {background: none;}
  .select select {font-size: 15px;font-weight: 600;border: none;box-shadow: none;border-radius: 30px;background: #ffffff;height: 100%;width: 100%;cursor: pointer;outline: none;padding-right: 35px;padding-left: 25px;border: 0px solid #CBCBCB;-moz-appearance: none;-webkit-appearance: none;color: #212121 !important;height: 50px;}
  .select select:-moz-focusring {color: transparent;}
  .select select::-ms-expand {display: none;}
  label {font-size: 15px; color: $gray-900; padding-left: 10px;}
  .form-control {font-size: 15px;border: 0px solid #cbcbcb;height: 50px;border-radius: 30px;box-shadow: 0 0 6px #cfcfcf; color: #393939; padding-left: 25px; font-weight: 600; text-transform: uppercase;}
}
.findcoach {
  .container {max-width: 1340px;}
  .search-form {background: #E1F2FE; padding: 50px 0 20px;
    label {font-size: 15px; color: $gray-900; padding-left: 10px;}
    .form-control {font-size: 15px;border: 0px solid #cbcbcb;height: 50px;border-radius: 30px;box-shadow: 0 0 6px #cfcfcf; color: #393939; padding-left: 25px; font-weight: 600; text-transform: uppercase;}
    textarea.form-control {height: 97px; border-radius: 17px; resize: none;}
    .form-group {margin-bottom: 30px;}
    .select {position: relative;background: white; border-radius: 30px; box-shadow: 0 0 6px #cfcfcf;}
    .select:after {content: '';position: absolute;top: -2px;width: 0;height: 0;right: 25px;bottom: 0;margin: auto;border-style: solid;border-width: 6px 6px 0 6px;border-color: #212121 transparent transparent transparent;pointer-events: none;}
    .select:before {width: 30px;position: absolute;top: 1px;right: 1px;bottom: 1px;background: none;content: '';pointer-events: none;}
    .select:hover:before {background: none;}
    .select select {font-size: 15px;font-weight: 600;border: none;box-shadow: none;border-radius: 30px;background: #ffffff;height: 100%;width: 100%;cursor: pointer;outline: none;padding-right: 35px;padding-left: 25px;border: 0px solid #CBCBCB;-moz-appearance: none;-webkit-appearance: none;color: #212121 !important;height: 50px;}
    .select select:-moz-focusring {color: transparent;}
    .select select::-ms-expand {display: none;}
    .btn-primary {color: #ffffff;background-color: $primary;border-color: $primary; padding: 0 0px; margin: 29px 0 0 0; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none; width: 100%;}
    .btn-primary:hover {color: $white; background-color: $secondary;border-color: $secondary;}
    .btn-secondary {color: #ffffff;background-color: $secondary;border-color: $secondary; padding: 0 0px; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none; width: 100%;
      i {font-size: 20px; margin: 0 0 0 8px; vertical-align: middle;}
    }
    .btn-secondary:hover {color: $white; background-color: $primary;border-color: $primary;}
    .form-control {
      .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {padding: 0px; border: none;}
      .MuiOutlinedInput-notchedOutline {border: none;}
      .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {color: #393939;padding-left: 0px;font-family: 'Poppins', sans-serif !important;text-transform: uppercase;font-size: 15px;font-weight: 600;}
      .MuiAutocomplete-endAdornment .MuiIconButton-root {color: #212121 !important;}
      .MuiSvgIcon-root {font-size: 29px;}
      .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment {right: 3px; top: 0px; }
    }
  }
  .searchresult {padding: 50px 0 50px 0;
    .coachbox {box-shadow: 0 0 6px #cfcfcf; border-radius: 8px; position: relative; margin: 0 0 30px 0;
      .image {width: 197px; display: inline-block; margin: 0 20px 0 0; position: relative;
        .featured {position: absolute; background-color: $primary; color: $white; top: 0px; left: 0px; padding: 5px 10px; text-transform: uppercase; border-radius: 0px 15px 15px 0px;}
        img {width: 197px; height: 201px; object-fit: cover; object-position: center; border-bottom-left-radius: 8px; border-top-left-radius: 8px;}
      }
      .detail {display: inline-block; vertical-align: top; width: 423px; padding: 15px 0 0px 0; color: #393939;
        .rating {font-size: 15px; color: #FFD203; float: right; padding: 0 15px 0 0;
          i {margin: 0 1px;}
        }
        h4 {font-size: 18px; font-weight: 600; color: #393939; margin: 0 0 15px 0;}
        .location {font-size: 15px; margin: 0 0 15px 0;
          img {margin: 0 15px 5px 0; float: left;}
        }
        .ypractice {font-size: 15px; margin: 0 0 12px 0;
          span {font-weight: 600;}
        }
        .grading {height: 30px; vertical-align: top; line-height: 30px; font-size: 14px; border-radius: 15px; background: #E1F2FE; display: inline-block; padding: 0 15px;width: 290px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
        .toolsdiv {display: inline-block; vertical-align: -moz-middle-with-baseline; width: 20px; height: 20px; background: $secondary; border-radius: 100%; text-align: center; color: $white; margin: 0 0 0 10px;}
        .viewbtn {display: block; width: 72px; height: 67px; background:url("images/cl-vb-nor.png") center center no-repeat; padding: 34px 9px 0 0; text-align: right;position: absolute;right: 0;bottom: -1px;}
      }
    }
    .coachbox:hover {
      .viewbtn {background:url("images/cl-vb-hor.png") center center no-repeat;}
    }
    .pagination { margin-top: 20px; margin-bottom: 0px;
      .page-item {
        .page-link {border: 0px; font-size: 15px; font-weight: 600; color: #393939; text-transform: uppercase; padding: 0 7px; box-shadow: none; margin: 0 0; height: 25px; line-height: 25px; }
        .page-link:hover {background: none; color: $primary;}
      }
      .page-item.prev, .page-item.next {
        .page-link {background: $secondary; border-radius: 13px; color: $white; padding: 0 13px; margin: 0 20px;}
        .page-link:hover {background: $primary;}
      }
    }
  }
}
.MuiPagination-root {text-align: center; margin-top: 20px;
  .MuiPagination-ul {display: inline-block;
    li {display: inline-block;
      .MuiButtonBase-root {border: 0px; font-size: 15px; font-weight: 600; color: #393939; text-transform: uppercase; padding: 0 7px; box-shadow: none; margin: 0 0; height: 25px; line-height: 25px; }
      .MuiPaginationItem-textPrimary.Mui-selected {background: none; color: $primary;}
      .MuiPaginationItem-sizeLarge {min-width: 25px;}
    }
    li:first-child {
      .MuiButtonBase-root {background: $secondary; color: $white; margin: 0 15px;}
    }
    li:last-child {
      .MuiButtonBase-root {background: $secondary; color: $white; margin: 0 15px;}
    }
  }
}

.CP-individual {padding: 0 0 40px 0;
  .bannersec { text-align: center;
    img {height: 255px; width: 100%; object-fit: cover; object-position: center;}
  }
  .CP-detail-container {
    .CP-detail {box-shadow: 0 0 6px #cfcfcf; border-radius: 8px; padding: 20px 30px 5px; margin: -88px 0 40px 0; background: $white;
      .image {
        img {width: 90px; height: 90px; object-fit: cover; object-position: center; border-radius: 100%; border: 2px solid $white; box-shadow: 0 0 6px #cfcfcf;}
      }
      .detail-box {margin: 0 0 0 -75px;
        h4 {font-size: 15px; font-weight: 600; color: #393939; margin: 0 0px 20px 0;}
        .MuiRating-root {color: #FFD203; font-size: 21px; vertical-align: top;}
        .ratingvalue {display: inline-block; font-size: 12px; color: #393939; margin: 0 0 0 10px;}
        .location {font-size: 13px; color: #393939; margin: 0 50px 20px 0; display: inline-block;
          img {margin: 0 10px 0 0;}
        }
        .ypractice {font-size: 15px; margin: 0 0 15px 0;
          span {font-weight: 600;}
        }
      }
      .btndiv {
        .btn-primary {color: #ffffff;background-color: $primary;border-color: $primary; padding: 0 0px; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none; width: 100%;}
        .btn-primary:hover {color: $white; background-color: $secondary;border-color: $secondary;}
        .btn-secondary {color: #ffffff;background-color: $secondary;border-color: $secondary; padding: 0 0px; margin: 0px 0 20px 0; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none; width: 100%;}
        .btn-secondary:hover {color: $white; background-color: $primary;border-color: $primary;}
      }
    }
    .card-header {padding: 25px 40px !important; background: $white;
      h1 {font-size: 25px; font-weight: 600; color: $gray-900; text-transform: inherit !important; margin: 0 0 0 0;}
      .nav-link {padding: 0; font-size: 25px; font-weight: 600; color: $gray-900; text-transform: capitalize; margin: 0 50px 0 0;}
      .nav-link.active {color: $primary;}
    }
    .card-body {min-height: inherit !important; padding: 30px 40px !important;}
    .expertisesec { margin: 0 0 40px 0;
      .grading {height: 30px;line-height: 30px;font-size: 14px;border-radius: 15px;background: #E1F2FE;display: inline-block;padding: 0 15px; color: #393939; margin: 0 15px 15px 0;}
    }
    .aboutcoach { margin: 0 0 40px 0;
      .content {font-size: 15px; line-height: 32px; color: $gray-900;}
    }
    .experience { margin: 0 0 40px 0;
      .expbox {margin: 0 0 40px 0;
        h4 {font-size: 18px; font-weight: 600; text-transform: uppercase; color: $primary; margin: 0 0 15px 0;}
        .location {font-size: 15px; color: #393939; font-weight: 600; margin: 0 240px 0 0; display: inline-block;}
        .date {font-size: 15px; color: #393939; font-weight: 600; display: inline-block;}
        .content {font-size: 15px; line-height: 28px; color: $gray-900; margin: 18px 0 0 0;}
      }
      .expbox:last-child {margin-bottom: 0px;}
    }
    .education { margin: 0 0 40px 0;
      .edupbox {margin: 0 0 40px 0;
        .location {font-size: 15px; color: #393939; font-weight: 600; margin: 0 170px 0 0; display: inline-block;}
        .digree {font-size: 15px; color: #393939; font-weight: 600; margin: 0 170px 0 0; display: inline-block;}
        .date {font-size: 15px; color: #393939; font-weight: 600; display: inline-block;}
        .content {font-size: 15px; line-height: 28px; color: $gray-900; margin: 18px 0 0 0;}
      }
      .edupbox:last-child {margin-bottom: 0px;}
    }
    .award { margin: 0 0 40px 0;
      .awrbox {margin: 0 0 40px 0;
        h4 {font-size: 18px; font-weight: 600; text-transform: uppercase; color: #393939; margin: 0 0 15px 0;}
        .content {font-size: 15px; line-height: 28px; color: $gray-900; margin: 0px 0 0 0;}
      }
      .awrbox:last-child {margin-bottom: 0px;}
    }
    .ratingreview-section { margin: 0 0 40px 0;
      h1 {display: inline-block;}
      .rating {display: inline-block; vertical-align: super; margin: 0 0 0 50px;
        .MuiRating-root {color: #FFD203; font-size: 21px; vertical-align: top;}
        .text-rating {display: inline-block; font-size: 12px; color: #393939; margin: 0 0 0 10px;}
      }
      .card-body {padding: 0px !important;
        .list-group-item {padding: 20px 30px; border: 0px; border-bottom: 1px solid #CCCCCC; border-radius: 0px;}
        .right-site {float: left; border-right: 1px solid #CCCCCC; padding: 0 20px 0 0; width: 280px;
          .image {float: left; margin: 0 25px 0 0; vertical-align: top;
            img {border-radius: 100%; width: 80px; height: 80px; object-fit: cover; object-position: center;}
          }
          .text-name {font-size: 15px; font-weight: 600; color: $primary; text-transform: uppercase; margin: 0 0 10px 0;}
          .rating {margin: 0 0 0 0;
            .ratingvalue {font-size: 13px;color: #393939;margin: 0 0 0 0px;vertical-align: bottom;}
          }
          .text-date {font-size: 13px; color: #8A8A8A; margin: 0 0 10px 0;}
        }
        .comment {float: left; vertical-align: top; padding: 0 0 0 15px; width: 74.7%;
          .text {font-size: 15px; line-height: 28px; color: #393939; min-height: 68px;}
          .reply {border-top: 1px solid #CCCCCC; display: block; padding: 15px 0 0 0;
            .btn-link {padding: 0px; border: 0px; box-shadow: none; font-size: 15px; font-weight: 600; color: $secondary; text-decoration: none; text-transform: uppercase;}
          }
        }
        .list-group-item:last-child {border-bottom: 0px;}
        .list-group-item:nth-child(odd) {background: #F4F9FC;}
      }
    }
  }
}

.register-page {min-height: 1114px; background: #fff url("images/register-bg.png") center center no-repeat; background-size: cover;
  .login-box {max-width: 870px; margin: 145px 0 0 auto; background-color: rgba(0, 0, 0, 0.8); border-radius: 8px; padding: 50px; color: $white;
    h1 {font-size: 35px; font-weight: 700; color: $white; text-transform: uppercase; margin: 0 0 30px 0;}
    label {font-size: 15px; color: $white; padding-left: 10px;}
    .form-control {font-size: 15px;border: 0px solid #cbcbcb;height: 50px;border-radius: 30px;box-shadow: none;color: #393939; padding-left: 25px; font-weight: 600; text-transform: uppercase;}
    .form-group {margin-bottom: 50px;}
    .form-group-1 {margin-bottom: 30px;}
    .form-group-2 {margin-bottom: 35px;}
    .custom-control-label {font-size: 15px; font-weight: 400; padding-left: 20px; padding-top: 2px;
      a {color: $primary;}
    }
    .custom-control-label::after, .custom-control-label::before {width: 29px; height: 29px; top: 0px; background-color: transparent; border-color: $primary;}
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {background-color: $primary; border-radius: 4px;}
    .custom-radio {margin-right: 50px; margin-left: 10px;}
    .custom-radio:last-child {margin-right: 0px;}
    .custom-radio .custom-control-label::after, .custom-radio .custom-control-label::before {width: 24px; height: 24px;}
    .custom-radio .custom-control-input:checked ~ .custom-control-label::after {background-color: $primary; border-radius: 50px;}
    .custom-radio .custom-control-label {padding-left: 15px; padding-top: 0px;}
    .select {position: relative; background: white; border-radius: 30px;}
    .select:after {content: '';position: absolute;top: -2px;width: 0; height: 0; right: 18px; bottom: 0;margin: auto; border-style: solid; border-width: 6px 6px 0 6px; border-color: #212121 transparent transparent transparent; pointer-events: none;}
    .select:before {width: 30px;position: absolute;top: 1px;right: 1px;bottom: 1px;background: none;content: '';pointer-events: none;}
    .select:hover:before {background: none;}
    .select select {font-size: 15px;font-weight: 600;border: none;box-shadow: none;border-radius: 30px;background: #ffffff;height: 100%;width: 100%;cursor: pointer;outline: none;padding-right: 35px;padding-left: 25px;border: 0px solid #CBCBCB;-moz-appearance: none; -webkit-appearance: none;color: #212121 !important; height: 50px;}
    .select select:-moz-focusring {color: transparent;}
    .select select::-ms-expand {display: none;}
    .btn-primary {color: #ffffff;background-color: $primary;border-color: $primary; padding: 0 67px; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none;}
    .btn-primary:hover {color: $gray-900;background-color: $white;border-color: $white;}
    .registerbtn {text-align: right;
      .btn-secondary {color: #ffffff;background-color: $primary;border-color: $primary; padding: 0 0px; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; width: 220px; margin: 0 0 0 20px; box-shadow: none;}
      .btn-secondary:hover {color: $gray-900;background-color: $white;border-color: $white;}
    }
  }
}

.register-page.coach-register-page {min-height: 1114px; background: #fff url("images/become-coach-register-bg.png") center center no-repeat;background-size: cover;
  .login-box {
    .btn-primary {padding: 0 73px;}
  }
}

.register-page.school-register-page {min-height: 1114px; background: #fff url("images/become-school-register-bg.png") center center no-repeat;background-size: cover;
  .login-box {
    .btn-primary {padding: 0 73px;}
  }
}

.login-page {min-height: 950px; background: #fff url("images/login-bg.png") center center no-repeat;background-size: cover;
  .login-box {max-width: 570px; margin: 50px 0 0 auto; background-color: rgba(0, 0, 0, 0.8); border-radius: 8px; padding: 50px 35px; color: $white;
    h1 {font-size: 35px; font-weight: 700; color: $white; text-transform: uppercase; margin: 0 0 30px 0;}
    .form-control {font-size: 15px;border: 0px solid #cbcbcb;height: 50px;border-radius: 30px;box-shadow: none;color: #393939; text-transform: uppercase; font-weight: 600; padding-left: 25px;}
    .form-group {margin-bottom: 40px;}
    .form-group-2 {margin-bottom: 35px;}
    .custom-control-label {font-size: 15px; font-weight: 400; padding-left: 20px; padding-top: 2px;
      a {color: $primary;}
    }
    .custom-control-label::after, .custom-control-label::before {width: 29px;height: 29px;top: 0px; background-color: transparent; border-color: $primary;}
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {background-color: $primary; border-radius: 4px;}
    .forgot {padding-top: 2px; text-align: right;
      a {font-size: 15px; color: $primary;}
    }
    .btn-primary {color: #ffffff;background-color: $primary;border-color: $primary; padding: 0 73px; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none;}
    .btn-primary:hover {color: $gray-900;background-color: $white;border-color: $white;}
  }
}

.login-page.forgetPassword {min-height: 950px; background: #fff url("images/forgot-bg.png") center center no-repeat;background-size: cover;
  .login-box {padding-bottom: 60px;
    h1 {font-size: 35px; font-weight: 700; color: $white; text-transform: uppercase; margin: 0 0 18px 0;}
    .content {font-size: 15px; margin: 0 0 30px 0;}
    .backlogin {margin-left: 30px; color: $primary; font-size: 15px;}
    label {font-size: 15px; color: $white; padding-left: 10px;}
  } 
}

.dashboard-layout {background: #E1F2FE;
  .dashtopbg {background: $secondary; min-height: 265px;}
}

.card.custom {box-shadow: 0 0 6px #d6d6d6; border: none; border-radius: 8px;
  .card-header {border-bottom: 1px solid #DEDEDE; padding: 40px;
    h1 {font-size: 25px; font-weight: 600; color: $gray-900; text-transform: capitalize; margin: 0 0 0 0;
      span {font-weight: 400;}
    }
    .plzeast {float: right; font-size: 18px; color: $primary; font-weight: 600; margin: -26px 0 0 0;}
    .nav-link {padding: 0; font-size: 25px; font-weight: 600; color: $gray-900; text-transform: capitalize; margin: 0 50px 0 0;}
    .nav-link.active {color: $primary; text-decoration: underline;}
  }
  .card-header:first-child {border-radius: 8px 8px 0px 0px;}
  .card-body {padding: 40px; min-height: 470px;
    .table {margin-bottom: 0px; border-collapse:separate; border-spacing:0 20px; 
      th {padding: 15px 30px; font-size: 18px; font-weight: 600; color: #393939; border:1px solid #CFCECE; border-right: 0px; border-left: 0px; border-radius: 8px;}
      th:nth-child(2), th:nth-child(3) {border-radius: 0px;}
      th:last-child {border-right: 1px solid #CFCECE; border-top-left-radius: 0px; border-bottom-left-radius: 0px;}
      th:first-child {border-left: 1px solid #CFCECE; border-top-right-radius: 0px; border-bottom-right-radius: 0px;}
      td:nth-child(2), td:nth-child(3) {border-radius: 0px;}
      td {border: none; padding: 15px 30px; font-size: 16px; color: #393939; border:1px solid #CFCECE; border-right: 0px; border-left: 0px; border-radius: 8px;}
      td:last-child {border-right: 1px solid #CFCECE; border-top-left-radius: 0px; border-bottom-left-radius: 0px;}
      td:first-child {border-left: 1px solid #CFCECE; border-top-right-radius: 0px; border-bottom-right-radius: 0px;}
      td:last-child {border-right: 1px solid #CFCECE;}
      td:first-child {border-left: 1px solid #CFCECE;}
    }
  }
}

.coach-profile {
  label {font-size: 15px; color: $gray-900; padding-left: 10px;}
  .form-control {font-size: 15px;border: 0px solid #cbcbcb;height: 50px;border-radius: 30px !important;box-shadow: 0 0 6px #cfcfcf; color: #393939; padding-left: 25px; font-weight: 600;}
  textarea.form-control {height: 142px; border-radius: 30px; padding-top: 20px; resize: none;}
  .form-control:disabled, .form-control[readonly] {background: #E1F2FE;}
  .form-group {margin-bottom: 40px;}
  .form-group-other {margin-bottom: 25px;}
  .verify {text-align: right; margin-top: -15px; position: relative; z-index: 8; margin-bottom: 10px; color: white;
    .btn-primary {padding: 0px 7px; font-size: 8px; line-height: 28px;}
  }
  .select {position: relative;background: white; border-radius: 30px; box-shadow: 0 0 6px #cfcfcf;}
    .select:after {content: '';position: absolute;top: -2px;width: 0;height: 0;right: 25px;bottom: 0;margin: auto;border-style: solid;border-width: 6px 6px 0 6px;border-color: #212121 transparent transparent transparent;pointer-events: none;}
    .select:before {width: 30px;position: absolute;top: 1px;right: 1px;bottom: 1px;background: none;content: '';pointer-events: none;}
    .select:hover:before {background: none;}
    .select select {font-size: 15px;font-weight: 600;border: none;box-shadow: none;border-radius: 30px;background: #ffffff;height: 100%;width: 100%;cursor: pointer;outline: none;padding-right: 35px;padding-left: 25px;border: 0px solid #CBCBCB;-moz-appearance: none;-webkit-appearance: none;color: #212121 !important;height: 50px;}
    .select select:-moz-focusring {color: transparent;}
    .select select::-ms-expand {display: none;}
    .custom-file .custom-file-label {padding: 71px;text-align: center;background-color: #FFFFFF;color: #212121;border: 0px solid #CBCBCB;height: 142px;box-shadow: 0 0 6px #cfcfcf; border-radius: 30px;}
    .custom-file .custom-file-label i {display: block;text-align: center;font-size: 35px;margin-top: -20px;margin-bottom: 10px;color: $secondary;}
    .custom-file-label::after {display: none;}
    .custom-file .custom-file-input:focus {box-shadow: none;}
    .custom-file .custom-file-input {height: 142px;}
    .custom-file {height: 142px;}
    .downloadfile {font-size: 14px; font-weight: 600; color: $secondary; margin: 20px 0 0 0; display: inline-block;
      i {font-size: 40px;vertical-align: middle;margin: 0 10px 0 0;}
    }
    .MuiFormControl-marginNormal {margin: 0;}
    .MuiInputBase-input {padding: 0px; height: 50px; font-weight: 600; font-family: 'Poppins', sans-serif !important; font-size: 15px;}
    .MuiInput-underline::after {border: none;}
    .MuiIconButton-root {margin-right: 10px; padding: 6px; color: $secondary;}
    .MuiSvgIcon-root {width: 30px; height: 30px;}
    .radiobtn {
      .MuiIconButton-root {margin-right: 5px; color: $primary;}
      .MuiFormControlLabel-label {font-weight: 600; font-family: 'Poppins', sans-serif !important; font-size: 15px;}
      .MuiFormControlLabel-root {margin-right: 60px;}
    }
  .btn-primary {color: #ffffff;background-color: $primary;border-color: $primary; padding: 0 73px; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none;}
  .btn-primary:hover {color: $white; background-color: $secondary;border-color: $secondary;}
  .btn-primary.two {padding: 0 40px;}
  .btn-info {background: none !important; border: none !important; box-shadow: none !important; color: #393939 !important; text-transform: uppercase; line-height: 48px; padding: 0; margin: 0 0 0 30px;}
  h4 {font-size: 18px; font-weight: 600; color: #393939; margin: 0 0 20px 0;}
  .custom-control-label {padding-left: 13px;}
  .custom-control-label::after, .custom-control-label::before {width: 23px;height: 23px;top: 1px; background-color: transparent; border-color: $primary;}
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {background-color: $primary; border-radius: 4px;}
  .profile-photo {padding: 0 0;
    label {font-size: 18px; font-weight: 700; text-align: center; margin: 0 0 30px 0; display: block;
      input {opacity: 0; display: none;}
    }
    .pic {position: relative; width: 270px;
      .rounded-circle {width: 150px; height: 150px; object-fit: cover; object-position: center; margin: 0 auto 15px auto; display: block;}
      .btn-primary {padding: 0 0px; text-transform: capitalize; font-size: 15px; font-weight: 600; text-transform: uppercase;
        .fas {margin: 0 5px 0 0; vertical-align: sub; font-size: 24px;}
        img {width: 26px; margin: -3px 7px 0 0; height: 21px;}
      }
      .loader {left: 0; right: 0;
        span {left: 50px;right: 0;top: 50px;}
      }
    }
    .notes {font-size: 13px; color: $gray-900; line-height: 25px;
      ul {margin: 0 0 0 0; padding: 0 0 0 0; list-style: square;}
    }
  }
  .card-title.h5 {font-size: 18px; font-weight: 600; color: #393939; margin: 0 0 30px 0;
    .btn-outline-primary {color: $primary;background-color: $white;border-color: $primary; padding: 0 38px; line-height: 38px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none;}
    .btn-outline-primary:hover {color: $white; background-color: $primary;border-color: $primary;}
  }
  .list-group-item {padding: 20px 0 20px 0; border: 0px; border-top: 1px solid #DADADA;
    h5 {font-size: 15px; font-weight: 600; color: #393939; margin: 0 0 15px 0;}
    .date {font-size: 15px; color: #393939; margin: 0 0 10px 0;}
    .content {font-size: 15px; color: #393939; margin: 0 0 0px 0; line-height: 28px;}
    p {margin: 0 0 0 0;}
    .button {float: right;
      .bg-primary, .bg-secondary {width: 46px; height: 46px; padding: 0px; line-height: 24px; border-radius: 100%; display: inline-block; margin: 0 10px; box-shadow: 0 0 6px #cfcfcf;}
    }
  }
  .MuiAutocomplete-root {
    .MuiOutlinedInput-root {border-radius: 30px; box-shadow: 0 0 6px #cfcfcf;}
    .MuiOutlinedInput-notchedOutline {border: 0px;}
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {padding: 0px;}
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {padding: 0px 0 0 0px;}
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment {right: 0px; top: 4px;}
    .MuiIconButton-root {color: #212121;}
    
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {padding-left: 25px;}
}

.coach-profile.coachapplication {
  label.semibold {font-weight: 600;}
  label.semiboldtwo {font-weight: 600; padding-left: 0px;}
  ol {padding: 0 0 0 15px;
    li {font-size: 15px; color: #393939; line-height: 35px;}
  }
  .hereby {
    .custom-checkbox {margin-bottom: 15px;}
  }
  .payment {margin: 30px 0 0 0;
    h3 {font-size: 15px; color: #393939; margin: 0 0 10px 0; font-weight: 600;}
    p {font-size: 15px; margin-bottom: 30px;}
  }
  .btn-primary {padding: 0 25px;}
}

.coach-profile.congratulation {
  .content {font-size: 15px; color: #393939; line-height: 28px;
    h4 {font-size: 15px; font-weight: 600; margin: 0 0 30px 0;}
    h5 {font-size: 15px; font-weight: 400; margin: 0 0 15px 0;}
    p {margin-bottom: 30px;}
    b {font-weight: 600;}
    a.thanktxt {font-weight: 600; text-decoration: underline; color: $primary;}
    a.support {font-weight: 400; text-decoration: none; color: $primary;}
    ol {padding: 0 0 1px 15px;
      li {margin-bottom: 15px;}
    }
  }
}

.coach-profile.onboarding {
  .downloadfile {font-size: 15px;font-weight: 400;color: #393939;margin: 20px 0 0 0;display: inline-block;
    i {font-size: 30px; margin: 10px 12px 0 0; color: $primary;}
  }
  h4 {font-size: 15px; margin: 0 0 30px 0;}
}

.coach-profile.thankyou {
  .content {font-size: 15px; color: #393939; line-height: 28px;
    p {margin: 0 0 10px 0;}
    h6 {font-size: 15px; color: #393939; font-weight: 600; margin: 20px 0 15px 0;}
    ul {padding: 0 0 7px 0; margin: 0; list-style: none;
      li {margin: 0 0 10px 0;
        img {margin: 0 15px 0 0;}
      }
    }
  }
  .btn-primary {padding: 0 39px;}
  .some {margin: 30px 0 0 0;
    .w-40 {
      .btn-default {color: $primary; padding: 0 30px; font-size: 25px; box-shadow: none !important;}
    }
  }
}

.coach-profile.addresses {
  .card-header {
    .add-new {float: right; margin: -10px 0 0 0;
      .btn-primary {color: $primary;background-color: $white; border-color: $primary; padding: 0 30px; line-height: 38px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none;
        i {font-size: 20px; margin: 0 0 0 8px; vertical-align: text-top;}
      }
      .btn-primary:hover {color: $white; background-color: $primary;border-color: $primary;}
    }
  }
  .addess-box {margin: 0 0 30px 0;
    .lable {font-weight: 600; margin-right: 20px;}
    .card-text {font-size: 15px; color: #393939;}
    .MuiRadio-colorSecondary.Mui-checked {color: $primary;}
    .card {box-shadow: 0 0 6px #d6d6d6; border: none; border-radius: 8px;}
    .action {
      .bg-primary, .bg-secondary {width: 46px; height: 46px; padding: 0px; line-height: 40px; border-radius: 100%; display: inline-block; margin: 0 0px 0 20px; box-shadow: 0 0 6px #cfcfcf;}
    }
    .title {margin: -23px 0 0 0;}
  }
  
}

.calendar-management {
  .currentest {font-size: 25px; font-weight: 700; color: $white; text-transform: uppercase; float: right; margin: -25px 100px 0 0;}
  .verify {float: right;}
  h3 {font-size: 18px; font-weight: 600; color: #393939;}
  .MuiSelect-select.MuiSelect-select {line-height: 50px;}
  .MuiSelect-icon {right: 12px; top: 10px;}
  .MuiSelect-select:focus {background: none;}
  .action {padding: 25px 0 0 15px;}
  .MuiIconButton-root {color: $primary;}
  .MuiButton-containedSecondary {background: $white; color: $primary; border: 1px solid $primary; border-radius: 30px; box-shadow: none !important; font-size: 15px; font-family: 'Poppins', sans-serif !important; width: 90px;}
  .MuiButton-containedSecondary:hover {background: $primary; color: $white;}
}

.coach-profile.packages {
  .card-header {
    .totalbalances {font-size: 15px; font-weight: 600; color: #393939; float: right; margin: -24px 0 0 0;}
  }
  .multi-color { margin: 0 0 0 0;
    .col-md-3 {padding-left: 33px; padding-right: 33px;}
    h2.title {font-size: 20px; font-weight: 600; color: $secondary; margin: 0 0 40px -15px;}
    h2.title.two {margin-top: 30px;}
    .card {border-radius: 0; border-bottom-left-radius: 120px; border-top-right-radius: 120px; box-shadow: 0 0 12px #cfcfcf; border: 0px; padding: 15px; margin: 0 0 50px 0;
      h3 {font-size: 20px; font-weight: 600; margin: 0 0 16px 0;}
      .price {width: 180px; height: 55px; font-size: 20px; font-weight: 300; color: $white; text-align: center; line-height: 55px; border-bottom-left-radius: 120px; border-top-right-radius: 120px; margin: 0 0 0 -55px;}
      h5 {font-size: 20px; font-weight: 600; margin: 30px 0 30px 0;}
      .list {
        p {margin: 0 0 10px 0; padding: 0 0 0 15px; border-radius: 4px; font-size: 14px; height: 40px; line-height: 40px;
          &.bg { background: #E9ECEF;}
          i {color: #378D00;font-size: 18px;vertical-align: middle;margin: 0 5px 0 0;}
        }
        //p:last-child {background: $white;}
      }
      .btndiv {margin: 50px 0 30px 0;
        div {font-size: 20px; font-weight: 600; text-transform: uppercase; text-align: right; cursor: pointer;}
      }
      &.blur {filter: blur(5px);}
    }
    .card.other-1 {
      h3 {color: #502989;}
      .price {background: #502989;}
      h5 {color: #502989;}
      .btndiv {
        div {color: #502989;}
      }
    }
    .card.other-2 {
      h3 {color: #084774;}
      .price {background: #084774;}
      h5 {color: #084774;}
      .btndiv {
        div {color: #084774;}
      }
    }
    .card.other-3 {
      h3 {color: #E44743;}
      .price {background: #E44743;}
      h5 {color: #E44743;}
      .btndiv {
        div {color: #E44743;}
      }
    }
    .card.other-4.bronze-plus {
      h3 {color: #E44743;}
      .price {background: #E44743;}
      h5 {color: #E44743;}
      .btndiv {
        div {color: #E44743;}
      }
    }
    .card.other-4 {
      h3 {color: #E5C008;}
      .price {background: #E5C008;}
      h5 {color: #E5C008;}
      .btndiv {
        div {color: #E5C008;}
      }
    }
    .card.other-5 {
      h3 {color: #E5C008;}
      .price {background: #E5C008;}
      h5 {color: #E5C008;}
      .btndiv {
        div {color: #E5C008;}
      }
    }
    .card.other-6 {
      h3 {color: #E5C008;}
      .price {background: #E5C008;}
      h5 {color: #E5C008;}
      .btndiv {
        div {color: #E5C008;}
      }
    }
    .card.other-7 {
      h3 {color: #00944E;}
      .price {background: #00944E;}
      h5 {color: #00944E;}
      .btndiv {
        div {color: #00944E;}
      }
    }
  }
}
.coach-profile.packages.activepack {
  .multi-color {
    .card {
      h3 {color: $secondary !important;}
      .price {background: $secondary !important;}
      h5 {color: $secondary !important;}
      .btndiv {
        div {color: $secondary !important;}
      }
    }
  }
}

.booking-list {
  .post-box {border: 1px solid #CFCECE; border-radius: 12px; margin: 0 0 30px 0; padding: 25px; position: relative;
    .image {margin: 0 20px 0 0;
      img {border-radius: 100%; width: 90px; height: 90px; border: 2px solid $white; box-shadow: 0 0 6px #cfcfcf;}
    }
    h5 {font-size: 18px; font-weight: 600; color: #393939; margin: 0 0 12px 0;}
    .rating {margin: 0 0 12px 0;
      .MuiRating-root {color: #FFD203; font-size: 21px; vertical-align: top;}
      .ratingvalue {display: inline-block; font-size: 13px; color: #393939; margin: 0 0 0 10px; vertical-align: bottom;}
      .MuiSvgIcon-root {width: 22px; height: 22px;}
    }
    .location {font-size: 13px; color: #393939;
      img {margin: 0px 7px 10px 0; float: left;}
    }
    .inform {
      .date {font-size: 15px; color: #393939; margin: 15px 0px 0px 0;
        span {color: $primary; font-weight: 600; margin: 0 10px 0 0;}
      }
    }
    .inform-duration {
      .date {font-size: 15px; color: #393939; margin: 15px 0px 0 0;
        span {color: $primary; font-weight: 600; margin: 0 10px 0 0;}
      }
      .badge {margin: 15px 0 0 0}
    }
    .viewbtn {display: block; width: 72px; height: 67px; background:url("images/cl-vb-nor.png") center center no-repeat; padding: 34px 9px 0 0; text-align: right;position: absolute;right: 0;bottom: -1px;}
  }
  .post-box:hover {
    .viewbtn {background:url("images/cl-vb-hor.png") center center no-repeat;}
  }
}

.booking-individual {
  .row.mar {margin: 0px -20px;}
  .col-sm-8.mar, .col-sm-4.mar {padding-right: 20px;padding-left: 20px;}
  .messagesec {box-shadow: 0 0 6px #d6d6d6; border: none; border-radius: 8px; background: $white; padding: 0px;
    .messages {
      .header {border-bottom: 1px solid #D8D5D5; padding: 10px;
        img {width: 42px; height: 42px; object-fit: cover; object-position: center; border-radius: 100%; display: inline-block;}
        h5 {font-size: 15px; font-weight: 600; color: #393939; margin: 0 0 0 15px; display: inline-block; vertical-align: text-bottom;}
      }
      .msg-list {padding: 10px 10px; height: 484px; overflow-y: auto;
        ul {padding: 0px; margin: 0px; list-style: none;
          li { margin: 0 0 30px 0;
            .text {background: #E1F2FE; padding: 8px 12px; font-size: 15px; color: #393939; border-radius: 20px;}
            .title {
              .name {font-size: 15px; color: $secondary; font-style: italic; float: left;}
              .time {font-size: 12px; color: #393939; float: right; margin: 2px 0 0 0;}
            }
            img {width: 100px;}
          }
          li.mine { margin: 0 0 30px 0;
            .text {background: #ffffff; padding: 8px 12px; font-size: 15px; color: #393939; border-radius: 20px; border: 1px solid #D8D5D5;}
            .title {
              .name {font-size: 15px; color: #393939; font-style: normal; float: right; font-weight: 600;}
              .time {font-size: 12px; color: #393939; float: left; margin: 2px 0 0 0;}
            }
          }
        }
      }
      .mes-footer {border-top: 1px solid #D8D5D5; padding: 10px 10px;
        .form-control {border: none; box-shadow: none; font-size: 15px; color: #393939; padding: 5px 0 0 0; resize: none; width: 389px; display: inline-block; vertical-align: top; height: 36px;}
        label {display: inline-block; margin-top: 0px !important; margin-bottom: 0px; margin-right: 15px !important;
          .MuiIconButton-root {padding: 6px;}
          .text-info {color: #7D7C7C !important;}
        }
        .fab-loading {display: inline-block;
          .MuiFab-sizeSmall {width: 36px; height: 36px; box-shadow: none;}
        }
        .MuiIcon-root {font-size: 20px;}
      }
    }
    
  }
  h3.coachtitle {font-size: 18px; color: #393939; font-weight: 600; margin: 0 0 20px 0;}
  .media {margin: 0 0 35px 0;
    .image {margin: 0 20px 0 0;
      img {border-radius: 100%; width: 90px; height: 90px; border: 2px solid $white; box-shadow: 0 0 6px #cfcfcf;}
    }
    h5 {font-size: 18px; font-weight: 600; color: #393939; margin: 0 0 12px 0;}
    .rating {margin: 0 0 12px 0;
      .MuiSvgIcon-root  {width: 22px;height: 22px;}
      .MuiRating-root {color: #FFD203; font-size: 21px; vertical-align: top;}
      .ratingvalue {display: inline-block; font-size: 13px; color: #393939; margin: 0 0 0 10px; vertical-align: bottom;}
    }
    .location {font-size: 13px; color: #393939;
      img {margin: 2px 7px 10px 0; float: left;}
    }
  }
  .inform {margin: 0 0 40px 0;
    .date {font-size: 15px; color: #393939; margin: 0px 0px 30px 0;
      span {color: $primary; font-weight: 600; margin: 0 10px 0 0;}
    }
  }
  .inform-duration {
    .date {font-size: 15px; color: #393939; margin: 0px 0px 30px 0;
      span {color: $primary; font-weight: 600; margin: 0 10px 0 0;}
    }
  }
  .session {font-size: 15px; color: #393939;
    .title {color: $primary; font-weight: 600; margin: 0 0px 10px 0;}
    .btn-primary {color: $secondary;background-color: $white;border-color: $secondary; padding: 0 25px; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none; margin: 0 10px 10px 0;}
    .btn-primary:hover {color: $white; background-color: $secondary;border-color: $secondary;}
  }
  .review-cancel {text-align: right;
    .btn-primary {padding: 0 25px; margin: 0 0px 0 0;}
  }
  .session-rem {font-size: 15px; color: #393939; margin: 35px 0px 0 0;
    span {color: $primary; font-weight: 600; margin: 0 10px 0 0;}
  }
  .titletea {font-size: 18px; font-weight: 600; color: #393939; margin: 0 0 20px 0;}
  .badge {height: 50px; line-height: 50px; font-size: 15px; font-weight: 400; padding: 0 25px; border-radius: 30px; color: $white; margin: 0 10px 0 0; text-transform: uppercase;}
}

.notifications {
  .list-group-item {margin: 0 0 20px 0; border: 1px solid #CFCECE; border-radius: 8px; padding: 10px 30px;
    h5 {font-size: 18px; font-weight: 600; color: #393939; margin: 12px 0 0 0;}
    p {margin: 0 0 0 0;
      span {font-size: 15px; color: #393939;margin: 0 300px 0 0px;}
    }
    .bg-primary {width: 46px; height: 46px; padding: 0px; line-height: 40px; border-radius: 100%; display: inline-block; margin: 0 0px 0 0px; box-shadow: 0 0 6px #cfcfcf;}
  }
}

.invitationreceived {
  .post-box {border: 1px solid #CFCECE; border-radius: 12px; margin: 0 0 30px 0; padding: 25px; position: relative;
    .image {margin: 0 20px 0 0;
      img {border-radius: 100%; width: 90px; height: 90px; border: 2px solid $white; box-shadow: 0 0 6px #cfcfcf;}
    }
    h5 {font-size: 18px; font-weight: 600; color: #393939; margin: 0 0 10px 0;}
    .location {font-size: 13px; color: #393939;
      img {margin: 0 7px 0 0;}
    }
    .invitdate {font-size: 15px; color: #393939; margin: -60px 0px 0 0; float: right;
        span {color: $primary; font-weight: 600; margin: 0 10px 0 0;}
    }
    .content {font-size: 13px; color: #393939; margin: 10px 0 0 0;}
    .btndiv {margin: 23px 0 0 0; text-align: right;
      .btn-primary {color: $white;background-color: $primary;border-color: $primary; padding: 0 0px; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none; width: 150px;}
      .btn-primary:hover {color: $white; background-color: $secondary;border-color: $secondary;}
      .badge-secondary {color: $white;padding: 0 0px;line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none; width: 150px;}
    }
  }
}

.myteachers {
  .add-new {margin: -40px 0 0 0; float: right;
    .btn-primary {color: $white;background-color: $primary;border-color: $primary; padding: 0 56px; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none;}
    .btn-primary:hover {color: $white; background-color: $secondary;border-color: $secondary;}
  }
  .post-box {border: 1px solid #CFCECE; border-radius: 12px; margin: 0 0 30px 0; padding: 25px; position: relative;
    .image {margin: 0 20px 0 0;
      img {border-radius: 100%; width: 90px; height: 90px; border: 2px solid $white; box-shadow: 0 0 6px #cfcfcf;}
    }
    h5 {font-size: 18px; font-weight: 600; color: #393939; margin: 0 0 10px 0;}
    .location {font-size: 13px; color: #393939;
      img {margin: 0 7px 0 0;}
    }
    .date {font-size: 15px; color: #393939; margin: 10px 0px 0 0;
        span {color: $primary; font-weight: 600; margin: 0 10px 0 0;}
    }
    .btndiv {margin: -11px 0 0px 0; text-align: right;
      .btn-primary {color: $white;background-color: $primary;border-color: $primary; padding: 0 0px; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none; width: 150px;}
      .btn-primary:hover {color: $white; background-color: $secondary;border-color: $secondary;}
      .btn-ses {margin-bottom: 6px;}
    }
  }
}
.myteachers.sentinvite {
  .btndiv {margin: 0px 0 0px 0;}
}

.user-search-list {margin-top: 30px;
  .media {margin: 0 0 20px 0;
    .image {margin: 0 20px 0 0;
      img {border-radius: 100%; width: 50px; height: 50px; border: 0px solid $white; box-shadow: 0 0 6px #cfcfcf;}
    }
    h5 {font-size: 16px; font-weight: 600; color: #393939; margin: 12px 0 0px 0;}
    .btn-primary {width: 150px; padding: 0;}
  }
}

.coach-profile.schoolbooking {
  .selectteacher {width: 465px; margin: -52px 0 -13px 0; float: right;}
  .nav {margin: 0 0 30px 0;
    .nav-item {
      .nav-link {padding: 0px; font-size: 21px; font-weight: 600; margin: 0 120px 0 0; color: #2E2E2E;}
      .nav-link:hover {color: $primary;}
      .nav-link.active {color: $primary;}
    }
  }
  .post-box {border: 1px solid #CFCECE; border-radius: 12px; margin: 0 0 30px 0; padding: 30px 30px 10px 30px; position: relative;
    .titlesec {font-size: 15px; color: #393939; margin: 0px 0px 20px 0;
      span {color: $primary; font-weight: 600; margin: 0 15px 0 0;}
    }
  }
}

.coach-profile.scdashboard {
  h2 {font-size: 25px; font-weight: 600; color: #393939; margin: 0 0 30px 0;}
  .dashmyteacher {border-top: 1px solid #CFCECE; padding: 30px 40px 0 40px; margin: 50px -40px 0 -40px;}
}

.writeareview {
  .wreviewform {
    .media {
      .image {margin: 0 20px 0 0;
        img {border-radius: 100%; width: 90px; height: 90px; border: 2px solid $white; box-shadow: 0 0 6px #cfcfcf;}
      }
      h5 {font-size: 18px; font-weight: 600; color: #393939; margin: 0 0 12px 0;}
      .rating {margin: 0 0 12px 0;
        .MuiRating-root {color: #FFD203; font-size: 21px; vertical-align: top;}
        .ratingvalue {display: inline-block; font-size: 13px; color: #393939; margin: 0 0 0 10px; vertical-align: bottom;}
        .MuiSvgIcon-root {width: 22px; height: 22px;}
      }
      .location {font-size: 13px; color: #393939;
        img {margin: 0px 7px 10px 0; float: left;}
      }
    }
  }
  .rating-view {margin: 65px 0;
    ul.list-group {list-style: none; text-align: center;
      li {width: 20%;
        .rating-btn {
          .btn-rate {padding: 0px; box-shadow: none; border: 0px;
            span {display: block; font-size: 15px; color: #393939; margin: 0 0 7px 0;}
            i {font-size: 15px; margin: 0 1px; color: $gray-700}
          }
        }
        .rating-btn.active {
          .btn-rate {
            i {color: #FFD203;}
          }
        }
        .smile-icon {margin-bottom: 10px;}
      }
    }
  }
}

.rating-review-page {
  .orborder {border-top: 1px solid #D5D4D4; text-align: center; margin: 50px 0 30px 0;
    span {font-size: 18px; color: #393939; font-weight: 600; background: $white; padding: 0 0px; margin: -14px auto 0 auto; width: 50px;  display: block;}
  }
  .coach-profile .custom-control-label {font-size: 18px; font-weight: 600; color: #393939;}
  .coach-profile .form-group h5 {font-size: 15px; font-weight: 600; color: #393939; margin: 0 0 10px 0;}
  .coach-profile .form-group p {font-size: 15px; color: #393939;}
  .coach-profile .select {max-width: 260px;}
  .custom-control.custom-radio {margin: 0 0 30px 0;}
  .coach-profile textarea.form-control {max-width: 570px; resize: none;}
  .coach-profile .btn-primary {padding: 0 50px;}
}


.cookies {position: fixed; left: 0px; bottom: 0px; background-color: rgba(0, 0, 0, 0.8); padding: 10px; z-index: 999; color: $white; text-align: center;
  h1 {font-size: 25px; font-weight: 600; color: $white; margin: 0 0 20px 0; text-transform: uppercase;}
  .btn-primary {color: $white;background-color: $primary;border-color: $primary; padding: 0 20px; line-height: 48px; border-radius: 30px; font-size: 15px; text-transform: uppercase; font-weight: 600; box-shadow: none;}
  .btn-primary:hover {color: $white; background-color: $secondary;border-color: $secondary;}
}

.custom-ui.WelcomeDialog {min-width: 970px; max-width: 970px;
  .card {border-radius: 8px;}
  .card-header {background-color: $white; display: flex;
    h1 {font-size: 25px; font-weight: 600; color: $gray-900; font-family: 'Poppins', sans-serif !important; text-align: center; width: 100%; text-transform: uppercase; margin: 0px; padding: 12px 0px 12px;}
    button {float: right; padding: 0; color: #2e2e2e; font-size: 18px; background: none; border: 1px solid #2e2e2e; width: 30px; height: 30px; margin: 10px 0 0;}
  }
  .card-body {font-size: 15px; color: #393939; line-height: 28px;
    p {margin-bottom: 25px;}
    p.semibold {font-weight: 600; text-transform: uppercase;}
    a {font-weight: 700; text-decoration: underline;}
  }
}

@media (min-width: 1366px) and (max-width: 1870px) {
  .homebanner {min-height: auto; background-size: cover;}
  .homebanner h1 {font-size: 80px;}
  .homebanner .content {font-size: 35px; margin: 0 0 145px 0;}
  .homebanner .content-two {font-size: 40px;}
  .homebanner .content-three {font-size: 50px;}
}

@media (min-width: 1200px) and (max-width: 1700px) {
  .card.custom .card-header .plzeast {float: none; margin: 10px 0 10px 0; text-align: left;}
}

@media (min-width: 1360px) and (max-width: 1755px) {
  .card.custom .card-header .nav-link {margin: 10px 50px 10px 0;}
  .profile-photo .col-sm-3 {flex: 0 0 33.3333%; max-width: 33.3333%;}
  .notifications .list-group-item p span {margin: 0 30px 0 0px;}
  .coach-profile.packages .multi-color .col-md-3 {flex: 0 0 33.3333%; max-width: 33.3333%;}
  .calendar-management .card-body .col-sm-6 {flex: 0 0 100%; max-width: 100%;}
}

@media (min-width: 1360px) and (max-width: 1600px) {
  .myteachers .post-box .col-sm-10, .myteachers .post-box .col-sm-2 {flex: 0 0 100%; max-width: 100%;}
  .myteachers .post-box .btndiv {display: inline-block; margin: 0 10px 0 0;}
}

@media (min-width: 1200px) and (max-width: 1481px) {
  .homeheader header .navbar-brand img.homelogo {width: 175px;}
  .homeheader header .navbar .navbar-nav .nav-item .nav-link {padding-right: 10px; padding-left: 10px; font-size: 14px;}
  .homeheader header .navbar .right-menus .headbtn .btn-primary {margin: 0 10px 0 0; font-size: 14px; padding: 0 10px;}
  header .navbar-brand img.innerlogo {width: 175px;}
  header .navbar .navbar-nav .nav-item .nav-link {padding-right: 10px; padding-left: 10px; font-size: 14px;}
  header .navbar .right-menus .headbtn .btn-primary {margin: 0 10px 0 0; font-size: 14px; padding: 0 10px;}
}

@media (min-width: 992px) and (max-width: 1199px) {
  header .container {max-width: 960px;}
  .homeheader header .navbar-brand img.homelogo {width: 175px;}
  .homeheader header .navbar .right-menus .headbtn .btn-primary {margin: 0 10px 0 0; font-size: 14px; padding: 0 10px;}
  .homeheader header .navbar .right-menus {position: absolute; right: 30px; top: 85px;}
  header {min-height: 150px; background: $white !important;}
  header .navbar-brand img.innerlogo {width: 175px;}
  header .navbar .right-menus .headbtn .btn-primary {margin: 0 10px 0 0; font-size: 14px; padding: 0 10px;}
  header .navbar .right-menus {position: absolute; right: 30px; top: 85px;}
  header .navbar .right-menus .navbar-profile {position: absolute; right: 0; top: -66px;}
  header .navbar .right-menus .navbar-profile .profile-dropdown .username {display: none;}
  header .navbar .navbar-nav .nav-item .nav-link.dropdown-toggle img {margin: 0;}
  header .navbar .navbar-nav .nav-item .nav-link.dropdown-toggle::after {display: none;}
  .navbar-expand-lg .navbar-nav .dropdown-menu {right: 0; left: inherit;}
  .footer .footer-bottom .footermenu {margin: 0 0 0 -75px;}
  .homebanner .container {max-width: 960px;}
  .homebanner h1 {font-size: 54px;}
  .homeprofessional .container {max-width: 960px;}
  .homeprofessional .image img {width: 129px; height: 129px;}
  .homeprofessional .content {font-size: 23px;}
  .homewereach .container {max-width: 960px;}
  .homewereach .wrbox h3 {font-size: 20px;}
  .homegetmatched .container {max-width: 960px;}
  .homecompare .educoachtable .edurow .educol-1 {width: 530px; padding: 20px 0 20px 60px;}
  .homecompare .educoachtable .edurow .educol-2 {width: 200px;}
  .homecompare .educoachtable .edurow .educol-3 {width: 200px;}
  .homecompare .educoachtable .edurow .countnum {left: -30px}
  .hometestimonials h2 {font-size: 40px;}
  .hometestimonials .content {font-size: 20px;}
  .homeimproving h1 {padding: 0 65px;}
  .homeimproving h3 {font-size: 21px;}
  .about-page .aboutbanner .container {max-width: 960px;}
  .about-page .aboutourcoaches .image img {width: 129px; height: 129px;}
  .about-page .aboutourcoaches .name {font-size: 18px;}
  .about-page .aboutstori .image img {width: 100%;}
  .about-page .aboutpartner .prbox .primage img {width: 100%; height: 261px; object-fit: cover; object-position: center;}
  .about-page .aboutpartner .prbox .detail {padding: 30px 0 0 0px;}
  .about-page .aboutleadership .image img {width: 100%; height: auto !important;}
  .about-page .aboutadvisory .image img {width: 129px; height: 129px;}
  .about-page .aboutadvisory .name {font-size: 15px;}
  .about-page .aboutresources .prbox .primage img {width: 100%; height: 261px; object-fit: cover; object-position: center;}
  .about-page .aboutresources .prbox .detail {padding: 30px 0 0 0px;}
  .contact-page .container {max-width: 960px;}
  .contact-page .contact-box .contact-detail {min-height: 881px;}
  .contact-page .contact-box .contact-detail .info ul li {padding: 0 0px 0 0;}
  .faqpage .container {max-width: 960px;}
  .applytocoach .container {max-width: 960px;}
  .applytoschool .container {max-width: 960px;}
  .applytoschool .btndiv .btn-primary {float: left;}
  .applytoschool .container {max-width: 960px;}
  .dashboard-layout {background: $secondary;}
  .dashboard-layout #left-side .dashlogo {text-align: left;}
  .dashboard-layout .dashcontainer {max-width: 960px; margin: -265px auto 0 auto; padding-right: 15px; padding-left: 15px;}
  .dashboard-layout #left-side {width: 100%; margin: 0px 0px 0px 0;}
  .dashboard-layout #left-side .side-inner {width: 100%; min-height: auto; border-radius: 8px;}
  .dashboard-layout .aside-menu > li {width: 33.3333%; float: left;}
  .dashboard-layout .aside-menu > li > a:hover {background: $white;}
  .dashboard-layout .aside-menu > li > a.active:hover {background: #E9ECEF;}
  .dashboard-layout #right-side {width: 100%;}
  .dashboard-layout #right-side .dashheader .col-sm-2 {display: none;}
  .dashboard-layout #right-side .dashheader .col-sm-10 {position: absolute; top: 40px; margin: 0 0px 0 0px; right: 15px;}
  .dashboard-layout #right-side .dashheader .welcometext h1 {margin: 0px 0 10px 0;}
  .dashboard-layout #right-side .dashheader .welcometext {margin-bottom: 15px;}
}

@media (min-width: 768px) and (max-width: 991px) {
  header .container {max-width: 720px;}
  .homeheader header .navbar-brand {display: block; width: 100%; margin: 0 0 15px 0; text-align: center;}
  .homeheader header .navbar-brand img.homelogo {display: inline-block; width: 175px;}
  .homeheader header .navbar-collapse {position: absolute;left: 10px;top: 80px;}
  .homeheader header .navbar-light .navbar-toggler {position: absolute; padding: 0.25rem 6px; left: 25px; top: 25px; border-width: 2px; border-color: $white;}
  .homeheader header .navbar-light .navbar-toggler-icon {background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255,255,255)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");}
  .homeheader header .navbar .right-menus {display: block !important; width: 100%; text-align: right;}
  header .navbar-brand {display: block; width: 100%; margin: 0 0 15px 0; text-align: center;}
  header .navbar-brand img.innerlogo {display: inline-block; width: 175px;}
  header .navbar-collapse {position: relative;left: -14px; top: -25px;}
  header .navbar-light .navbar-toggler {position: absolute; padding: 0.25rem 6px; left: 25px; top: 25px; border-width: 2px; border-color: $secondary;}
  header .navbar-light .navbar-toggler-icon {background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(8,71,116)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");}
  header .navbar .right-menus {display: block !important; width: 100%; text-align: right;}
  header .navbar {padding-bottom: 20px;}
  header .navbar .right-menus .navbar-profile .profile-dropdown {position: absolute; right: 20px; top: 24px;}
  header .navbar-profile .notify {position: absolute; right: 70px; top: 24px;}
  header .navbar .right-menus .navbar-profile .profile-dropdown .username {display: none;}
  header .navbar .navbar-nav .nav-item .nav-link.dropdown-toggle img {margin: 0;}
  header .navbar .navbar-nav .nav-item .nav-link.dropdown-toggle::after {display: none;}
  .navbar-expand-lg .navbar-nav .dropdown-menu {right: 0; left: inherit;}
  .footer .footer-bottom .footermenu {margin: 0 0 0 -60px;}
  .footer .footer-bottom .footermenu ul li {font-size: 12px;}
  .footer .footer-bottom .footermenu ul li a {font-size: 12px;}
  .homebanner .container {max-width: 720px;}
  .homebanner h1 {font-size: 40px;}
  .homebanner .bannerbtn ul li {width: 30%;}
  .hometheworld h1 {font-size: 25px;}
  .hometheworld .twbox h3 {font-size: 17px; line-height: 26px;}
  .homeprofessional .container {max-width: 720px;}
  .homeprofessional .image img {width: 89px; height: 89px;}
  .homeprofessional .content {font-size: 20px; line-height: 40px;}
  .homeprofessional .content .selectlink {font-size: 29px;}
  .homewereach .container {max-width: 720px;}
  .homewereach h1 {font-size: 44px;}
  .homewereach .col-sm-3 {flex: 0 0 33.3333%; max-width: 33.3333%;}
  .homewereach .wrbox h3 {font-size: 20px; line-height: 34px;}
  .homegetmatched .container {max-width: 720px;}
  .homegetmatched h1.maintitle {font-size: 40px;}
  .homegetmatched .communicatediv h1.subtitle {font-size: 40px;}
  .homecoaching h1 {font-size: 45px;}
  .homecoaching .coachingbox h3 {font-size: 19px; line-height: 30px;}
  .homecompare .educoachtable .edurow .educol-1 {width: 490px; padding: 20px 0 20px 60px; font-size: 22px;}
  .homecompare .educoachtable .edurow .educol-2 {width: 100px; font-size: 22px;}
  .homecompare .educoachtable .edurow .educol-3 {width: 100px; font-size: 22px;}
  .homecompare .educoachtable .edurow .countnum {left: -30px}
  .hometestimonials h2 {font-size: 30px; line-height: 60px;}
  .homeimproving h1 {padding: 0 0px;}
  .homeimproving .imrbox .image img {width: 100%;}
  .homeimproving h3 {font-size: 15px;}
  .homeimproving .homefaqs h1 {font-size: 40px;}
  .about-page .aboutbanner .container {max-width: 720px;}
  .about-page .aboutbanner h1 {font-size: 25px;}
  .about-page .aboutourcoaches .image img {width: 89px; height: 89px;}
  .about-page .aboutourcoaches .name {font-size: 16px;}
  .about-page .aboutstori .image img {width: 100%;}
  .about-page .aboutstori .content {font-size: 16px; line-height: 30px;}
  .about-page .aboutpartner .prbox .col-sm-5, .about-page .aboutpartner .prbox .col-sm-7 {flex: 0 0 100%; max-width: 100%;}
  .about-page .aboutpartner .prbox .primage img {width: 100%; height: 261px; object-fit: cover; object-position: center;}
  .about-page .aboutpartner .prbox .detail {padding: 30px 0 30px 20px;}
  .about-page .aboutleadership .image img {width: 100%; height: auto !important;}
  .about-page .aboutadvisory .image img {width: 89px; height: 89px;}
  .about-page .aboutadvisory .name {font-size: 15px;}
  .about-page .aboutresources .prbox .primage img {width: 100%; height: 261px; object-fit: cover; object-position: center;}
  .about-page .aboutresources .prbox .col-sm-5, .about-page .aboutresources .prbox .col-sm-7 {flex: 0 0 100%; max-width: 100%;}
  .about-page .aboutresources .prbox .detail {padding: 30px 0 30px 20px; min-height: 238px;}
  .contact-page .contact-box .contact-detail {min-height: 881px;}
  .contact-page .contact-box .contact-detail .info ul li {padding: 0 0px 0 0; font-size: 12px;}
  .contact-page .contact-box .contact-detail .info ul li a {font-size: 12px;}
  .contact-page .contact-box .contact-form {padding: 40px 0px 40px 40px;}
  .contact-page .contact-box .contact-form .content {font-size: 14px; line-height: 22px;}
  .faqpage .faq-left .fl-header {font-size: 22px;}
  .faqpage .faq-left .fl-footer .btndiv .btn-primary {padding: 0 40px;}
  .applytoschool .content img {margin: 9px 25px 15px 0;}
  .applytoschool .btndiv .btn-primary {float: left;}
  .dashboard-layout {background: $secondary;}
  .dashboard-layout #left-side .dashlogo {text-align: left;}
  .dashboard-layout .dashcontainer {max-width: 720px; margin: -265px auto 0 auto; padding-right: 15px; padding-left: 15px;}
  .dashboard-layout #left-side {width: 100%; margin: 0px 0px 0px 0;}
  .dashboard-layout #left-side .side-inner {width: 100%; min-height: auto; border-radius: 8px;}
  .dashboard-layout .aside-menu > li {width: 50%; float: left;}
  .dashboard-layout .aside-menu > li > a:hover {background: $white;}
  .dashboard-layout .aside-menu > li > a.active:hover {background: #E9ECEF;}
  .dashboard-layout #right-side {width: 100%;}
  .dashboard-layout #right-side .dashheader .col-sm-2 {display: none;}
  .dashboard-layout #right-side .dashheader .col-sm-10 {position: absolute; top: 40px; margin: 0 0px 0 0px; right: 15px;}
  .dashboard-layout #right-side .dashheader .welcometext h1 {margin: 0px 0 10px 0;}
  .dashboard-layout #right-side .dashheader .welcometext {margin-bottom: 15px;}
}

@media (min-width: 576px) and (max-width: 767px) {
  header .container {max-width: 540px;}
  .homeheader header .navbar-brand {display: block; width: 100%; margin: 0 0 15px 0; text-align: center;}
  .homeheader header .navbar-brand img.homelogo {display: inline-block; width: 175px;}
  .homeheader header .navbar-collapse {position: absolute;left: 10px;top: 80px;}
  .homeheader header .navbar-light .navbar-toggler {position: absolute; padding: 0.25rem 6px; left: 25px; top: 25px; border-width: 2px; border-color: $white;}
  .homeheader header .navbar-light .navbar-toggler-icon {background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255,255,255)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");}
  .homeheader header .navbar .right-menus {display: block !important; width: 100%; text-align: right;}
  .homeheader header .navbar .right-menus .headbtn .btn-primary {font-size: 12px; padding: 0 10px; line-height: 35px; margin: 0 10px 0 0;}
  .homeheader header .navbar .right-menus .headbtn .btn-secondary {font-size: 12px; padding: 0 10px; line-height: 35px;}
  .homeheader header .navbar .navbar-nav .nav-item .nav-link {font-size: 12px;}
  header .navbar-brand {display: block; width: 100%; margin: 0 0 15px 0; text-align: center;}
  header .navbar-brand img.innerlogo {display: inline-block; width: 175px;}
  header .navbar-collapse {position: relative;left: -8px; top: -25px;}
  header .navbar-light .navbar-toggler {position: absolute; padding: 0.25rem 6px; left: 25px; top: 25px; border-width: 2px; border-color: $secondary;}
  header .navbar-light .navbar-toggler-icon {background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(8,71,116)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");}
  header .navbar .right-menus {display: block !important; width: 100%; text-align: right;}
  header .navbar {padding-bottom: 20px;}
  header .navbar .right-menus .headbtn .btn-primary {font-size: 12px; padding: 0 10px; line-height: 35px; margin: 0 10px 0 0;}
  header .navbar .right-menus .headbtn .btn-secondary {font-size: 12px; padding: 0 10px; line-height: 35px;}
  header .navbar .navbar-nav .nav-item .nav-link {font-size: 12px;}
  header .navbar .right-menus .navbar-profile .profile-dropdown {position: absolute; right: 20px; top: 24px;}
  header .navbar-profile .notify {position: absolute; right: 70px; top: 24px;}
  header .navbar .right-menus .navbar-profile .profile-dropdown .username {display: none;}
  header .navbar .navbar-nav .nav-item .nav-link.dropdown-toggle img {margin: 0;}
  header .navbar .navbar-nav .nav-item .nav-link.dropdown-toggle::after {display: none;}
  .navbar-expand-lg .navbar-nav .dropdown-menu {right: 0; left: inherit;}
  .footer .footer-bottom .col-sm-1 {display: none;}
  .footer .footer-bottom .col-sm-3 {flex: 0 0 50%; max-width: 50%;}
  .footer .footer-bottom .footermenu {margin: 0 0 0 0;}
  .footer .footer-bottom .footercontact li {font-size: 14px;}
  .footer .footer-bottom .footermenu ul li a {font-size: 14px;}
  .footer .footer-bottom .footermenu.two {margin-top: 30px;}
  .footer .footer-top .footer-content {padding: 0 130px;}
  .homebanner .container {max-width: 540px;}
  .homebanner h1 {font-size: 39px;}
  .homebanner .bannerbtn ul li {width: 30%;}
  .hometheworld {padding-bottom: 40px;}
  .hometheworld h1 {font-size: 25px;}
  .hometheworld .col-sm-4 {flex: 0 0 100%; max-width: 100%;}
  .hometheworld .twbox {margin-bottom: 50px;}
  .hometheworld .twbox h3 {font-size: 17px; line-height: 26px;}
  .homeprofessional .container {max-width: 540px;}
  .homeprofessional .col-sm-1 {flex: 0 0 100%; max-width: 100%;}
  .homeprofessional .col-sm-2 {flex: 0 0 100%; max-width: 100%;}
  .homeprofessional .image {margin-bottom: 30px;}
  .homeprofessional .image img {width: 165px; height: 165px;}
  .homeprofessional .content {font-size: 20px; line-height: 40px; margin: 30px 0 0 0;}
  .homeprofessional .content .selectlink {font-size: 29px;}
  .homewereach .container {max-width: 540px;}
  .homewereach h1 {font-size: 33px;}
  .homewereach h2 {font-size: 28px; line-height: 50px;}
  .homewereach .col-sm-3 {flex: 0 0 50%; max-width: 50%;}
  .homewereach .wrbox h3 {font-size: 20px; line-height: 34px;}
  .homegetmatched .container {max-width: 540px;}
  .homegetmatched h1.maintitle {font-size: 31px;}
  .homegetmatched .communicatediv h1.subtitle {font-size: 31px;}
  .homegetmatched .communicatediv .image img {width: 100%;}
  .homegetmatched .communicatediv h3 {font-size: 24px;}
  .homecoaching h1 {font-size: 36px;}
  .homecoaching .coachingbox h3 {font-size: 18px; line-height: 30px; padding: 0 0px;}
  .homecompare h1 {font-size: 40px;}
  .homecompare .educoachtab.nav.nav-tabs .nav-item .nav-link {width: 255px;}
  .homecompare .educoachtable .edurow .educol-1 {width: 390px; padding: 20px 0 20px 60px; font-size: 17px;}
  .homecompare .educoachtable .edurow .educol-2 {width: 60px; font-size: 17px;}
  .homecompare .educoachtable .edurow .educol-3 {width: 60px; font-size: 17px;}
  .homecompare .educoachtable .edurow .countnum {left: -30px}
  .hometestimonials h2 {font-size: 30px; line-height: 60px;}
  .hometestimonials .col-sm-5, .hometestimonials .col-sm-7 {flex: 0 0 100%; max-width: 100%;}
  .hometestimonials .content {margin: 0 0 30px 0;}
  .homeimproving h1 {padding: 0 0px; font-size: 40px;}
  .homeimproving .imrbox .image img {width: 100%;}
  .homeimproving h3 {font-size: 15px;}
  .homeimproving .homefaqs h1 {font-size: 40px;}
  .homeimproving .homefaqs .accordion .card .card-header .btn-link h4 {font-size: 15px;}
  .about-page .aboutbanner .container {max-width: 540px;}
  .about-page .aboutbanner h1 {font-size: 25px;}
  .about-page .aboutourcoaches {padding-bottom: 70px;}
  .about-page .aboutourcoaches .col-sm-1, .about-page .aboutourcoaches .col-sm-2 {flex: 0 0 100%; max-width: 100%;}
  .about-page .aboutourcoaches .image {margin: 0 0 10px 0;}
  .about-page .aboutourcoaches .name {margin-bottom: 30px;}
  .about-page .aboutstori .image img {width: 100%;}
  .about-page .aboutstori .content {font-size: 16px; line-height: 30px;}
  .about-page .aboutpartner .col-sm-6 {flex: 0 0 100%; max-width: 100%;}
  .about-page .aboutpartner .prbox {margin-bottom: 30px;}
  .about-page .aboutpartner .prbox .primage img {width: 100%; height: 261px; object-fit: cover; object-position: center;}
  .about-page .aboutpartner .prbox .detail {padding: 30px 0 30px 0px;}
  .about-page .aboutleadership .image img {width: 100%; height: auto !important;}
  .about-page .aboutadvisory .col-sm-2 {flex: 0 0 100%; max-width: 100%;}
  .about-page .aboutadvisory .image {margin-bottom: 10px;}
  .about-page .aboutadvisory .name { margin-bottom: 30px;}
  .about-page .aboutresources .col-sm-6 {flex: 0 0 100%; max-width: 100%;}
  .about-page .aboutresources .prbox .primage img {width: 100%; height: 261px; object-fit: cover; object-position: center;}
  .about-page .aboutresources .prbox .detail {padding: 30px 0 30px 0px;}
  .contact-page .contact-box .col-sm-7, .contact-page .contact-box .col-sm-5 {flex: 0 0 100%; max-width: 100%;}
  .contact-page .contact-box .contact-detail {min-height: auto;}
  .contact-page .contact-box .contact-detail .info ul li {padding: 0 0px 0 0;}
  .contact-page .contact-box .contact-form {padding: 40px 40px 40px 40px;}
  .faqpage .col-sm-4, .faqpage .col-sm-8 {flex: 0 0 100%; max-width: 100%;}
  .faqpage .faq-left {margin-bottom: 30px;}
  .content-page .contenttxt img {width: 100%;}
  .applytocoach .content p.ultxt img {float: left; margin: 5px 8px 8px 0;}
  .applytocoach .btndiv .btn-primary {float: left;}
  .applytoschool .content img {margin: 9px 25px 45px 0;}
  .applytoschool .btndiv .btn-primary {float: left;}
  .applytoschool h2 img {margin: 5px 25px 0 0; float: left;}
  .register-page .login-box .custom-radio {margin-right: 9px;}
  .dashboard-layout {background: $secondary;}
  .dashboard-layout #left-side .dashlogo {text-align: left;}
  .dashboard-layout .dashcontainer {max-width: 540px; margin: -265px auto 0 auto; padding-right: 15px; padding-left: 15px;}
  .dashboard-layout #left-side {width: 100%; margin: 0px 0px 0px 0;}
  .dashboard-layout #left-side .side-inner {width: 100%; min-height: auto; border-radius: 8px;}
  .dashboard-layout .aside-menu > li {width: 50%; float: left;}
  .dashboard-layout .aside-menu > li > a {padding: 0px 0px 0 10px;}
  .dashboard-layout .aside-menu > li > a:hover {background: $white;}
  .dashboard-layout .aside-menu > li > a.active:hover {background: #E9ECEF;}
  .dashboard-layout #right-side {width: 100%;}
  .dashboard-layout #right-side .dashheader .col-sm-2 {display: none;}
  .dashboard-layout #right-side .dashheader .col-sm-10 {position: absolute; top: 40px; margin: 0 0px 0 0px; right: 15px;}
  .dashboard-layout #right-side .dashheader .welcometext h1 {margin: 0px 0 25px 0; font-size: 25px;}
  .dashboard-layout #right-side .dashheader .welcometext {margin-bottom: 15px;}
}

@media (min-width: 320px) and (max-width: 575px) {
  .MuiPaper-root.MuiDialog-paperScrollBody .form-control, .MuiDialog-root.zipcode-dialog .MuiPaper-root .form-control {font-size: 11px; padding-left: 15px;}
  .homeheader header {background-image: none !important; padding: 10px 0;}
  header .container {max-width: 540px;}
  .homeheader header .navbar-brand {display: block; width: 100%; margin: 0 0 0 0; text-align: center;}
  .homeheader header .navbar-brand img.homelogo {display: inline-block; width: 120px;}
  .homeheader header .navbar-collapse {position: relative;left: 0px;top: 0px;}
  .homeheader header .navbar-light .navbar-toggler {position: absolute; padding: 0.25rem 6px; left: 10px; top: 10px; border-width: 2px; border-color: $secondary;}
  .navbar-toggler-icon {width: 22px; height: 22px;}
  .homeheader header .navbar-light .navbar-toggler-icon {background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(8,71,116)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");}
  .homeheader header .navbar .right-menus {display: block !important; width: 100%; text-align: right;}
  .homeheader header .navbar .right-menus .headbtn .btn-primary {font-size: 12px; padding: 0 10px; line-height: 35px; margin: 0 auto 5px auto;display: block;width: 200px;}
  .homeheader header .navbar .right-menus .headbtn .btn-secondary {font-size: 12px; padding: 0 10px; line-height: 35px; margin: 0 auto 0px auto;display: block;width: 200px;}
  .homeheader header .navbar .navbar-nav .nav-item .nav-link {font-size: 12px; color: $gray-900; text-align: center;}
  .homeheader header .navbar-brand img.homelogo {display: none;}
  .homeheader header .navbar-brand img.innerlogo {display: inline-block;}
  .homeheader header .navbar .right-menus .headbtn .btn-secondary {color: $gray-900;background-color: transparent;border-color: $gray-900;}
  header .navbar-brand {display: block; width: 100%; margin: 0 0 15px 0; text-align: center;}
  header .navbar-brand img.innerlogo {display: inline-block; width: 120px;}
  header .navbar-collapse {position: relative;left: 0; top: 0;}
  header .navbar-light .navbar-toggler {position: absolute; padding: 0.25rem 6px; left: 10px; top: 10px; border-width: 2px; border-color: $secondary;}
  header .navbar-light .navbar-toggler-icon {background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(8,71,116)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");}
  header .navbar .right-menus.d-sm-none {display: none !important; width: 100%; text-align: right;}
  header .navbar {padding-bottom: 20px;}
  header .navbar .right-menus .headbtn .btn-primary {font-size: 12px; padding: 0 10px; line-height: 35px; margin: 0 auto 5px auto;display: block;width: 200px;}
  header .navbar .right-menus .headbtn .btn-secondary {font-size: 12px; padding: 0 10px; line-height: 35px; margin: 0 auto 0px auto;display: block;width: 200px;}
  header .navbar .navbar-nav .nav-item .nav-link {font-size: 12px; text-align: center;}
  header .navbar .right-menus .navbar-profile .profile-dropdown {position: absolute; right: 10px; top: 14px;}
  header .navbar-profile .notify {position: absolute; right: 70px; top: 24px; display: none;}
  header .navbar .right-menus .navbar-profile .profile-dropdown .username {display: none;}
  header .navbar .navbar-nav .nav-item .nav-link.dropdown-toggle img {margin: 0; width: 35px; height: 35px;}
  header .navbar .navbar-nav .nav-item .nav-link.dropdown-toggle::after {display: none;}
  .navbar-expand-lg .navbar-nav .dropdown-menu {right: 0; left: inherit; position: absolute;}
  .footer .footer-bottom .col-sm-1 {display: none;}
  .footer .footer-bottom .col-sm-3 {flex: 0 0 100%; max-width: 100%;}
  .footer .footer-bottom .footermenu {margin: 0 0 0 0;}
  .footer .footer-bottom .footercontact li {font-size: 14px;}
  .footer .footer-bottom .footermenu ul li a {font-size: 14px;}
  .footer .footer-bottom .footermenu.two {margin-top: 30px;}
  .footer .footer-bottom .footermenu.footercontact {margin-top: 30px;}
  .footer .footer-top .footer-content {padding: 0 0px;}
  .homebanner {margin: 0 0 0 0; min-height: auto;}
  .homebanner .container {max-width: 100%;}
  .homebanner h1 {font-size: 18px; padding: 14px 0 0 0;}
  .homebanner .content {font-size: 14px; margin: 0 0 5px 0;}
  .homebanner .content-two {font-size: 15px; line-height: 18px; margin: 0 0 5px 0;}
  .homebanner .content-three {font-size: 18px; margin: 0 0 10px 0;}
  .homebanner .bannerbtn ul li {width: 35%; margin: 0 0px 0px 0;}
  .homebanner .downarrow {margin: 90px 0 0 0; display: none;}
  .homebanner .bannerbtn .btn-primary {margin: 0 0 10px 0; font-size: 11px; line-height: 26px; font-weight: 600;}
  .hometheworld {padding-bottom: 40px; padding-top: 40px;}
  .hometheworld h1 {font-size: 25px;}
  .hometheworld .col-sm-4 {flex: 0 0 100%; max-width: 100%;}
  .hometheworld .twbox {margin-bottom: 50px;}
  .hometheworld .twbox h3 {font-size: 17px; line-height: 26px;}
  .homeprofessional .container {max-width: 100%;}
  .homeprofessional .col-sm-1 {flex: 0 0 100%; max-width: 100%;}
  .homeprofessional .col-sm-2 {flex: 0 0 100%; max-width: 100%;}
  .homeprofessional h1 {font-size: 35px;}
  .homeprofessional .image {margin-bottom: 30px;}
  .homeprofessional .image img {width: 165px; height: 165px;}
  .homeprofessional .content {font-size: 18px; line-height: 30px; margin: 30px 0 0 0;}
  .homeprofessional .content .selectlink {font-size: 22px;}
  .homewereach .container {max-width: 100%;}
  .homewereach h1 {font-size: 25px;}
  .homewereach h2 {font-size: 23px; line-height: 35px;}
  .homewereach .col-sm-3 {flex: 0 0 100%; max-width: 100%;}
  .homewereach .wrbox h3 {font-size: 20px; line-height: 34px;}
  .homewereach .wrbox {margin-bottom: 50px;}
  .homewereach .muchmore {margin-top: -20px;}
  .homegetmatched .container {max-width: 100%;}
  .homegetmatched h1.maintitle {font-size: 25px;}
  .homegetmatched .content {font-size: 20px; line-height: 35px;}
  .homegetmatched .communicatediv h1.subtitle {font-size: 25px;}
  .homegetmatched .communicatediv .comcontent h2 {font-size: 18px;}
  .homegetmatched .communicatediv .comcontent h2 span {margin: 0 10px;}
  .homegetmatched .communicatediv .image img {width: 100%;}
  .homegetmatched .communicatediv h3 {font-size: 20px;}
  .homecoaching {padding: 0px 0px 50px 0;}
  .homecoaching h1 {font-size: 25px;}
  .homecoaching .coachingbox {margin: 0 0 50px 0;}
  .homecoaching .coachingbox h3 {font-size: 18px; line-height: 30px; padding: 0 0px;}
  .homecompare h1 {font-size: 25px;}
  .homecompare .educoachtab.nav.nav-tabs .nav-item .nav-link {width: 145px; height: 145px; padding: 40px 0 0 0; font-size: 16px; line-height: 30px;}
  .homecompare .educoachtab.nav.nav-tabs .nav-item .nav-link img {width: 125px;}
  .homecompare .educoachtable .edurow .countnum {display: none;}
  .homecompare .educoachtable .edurow .educol-1 {width: 100%; padding: 20px 0 20px 0px; font-size: 17px; text-align: center;}
  .homecompare .educoachtable .edurow .educol-2 {width: 100%; font-size: 17px; border: none; padding: 5px 0;}
  .homecompare .educoachtable .edurow .educol-3 {width: 100%; font-size: 17px; border: none;}
  .homecompare .educoachtable .edurow .countnum {left: -30px}
  .hometestimonials h1 {font-size: 25px;}
  .hometestimonials h2 {font-size: 23px; line-height: 45px;}
  .hometestimonials .col-sm-5, .hometestimonials .col-sm-7 {flex: 0 0 100%; max-width: 100%;}
  .hometestimonials .content {margin: 0 0 30px 0; font-size: 20px; line-height: 35px;}
  .hometestimonials .testibox h3 {font-size: 20px;}
  .hometestimonials .testibox .testcontent {font-size: 15px; line-height: 26px;}
  .hometestimonials .testibox .detail .name {font-size: 16px;}
  .homeimproving h1 {padding: 0 0px; font-size: 25px; line-height: 40px;}
  .homeimproving .col-sm-3 {flex: 0 0 50%; max-width: 50%;}
  .homeimproving .imrbox .image img {width: 100%;}
  .homeimproving h3 {font-size: 15px; margin: 0 0 30px 0;}
  .homeimproving .homefaqs {padding: 90px 0 0 0;}
  .homeimproving .homefaqs h1 {font-size: 25px;}
  .homeimproving .homefaqs .accordion .card .card-header .btn-link h4 {font-size: 15px;}
  .about-page .aboutbanner {margin: 20px 0 0; min-height: 430px;}
  .about-page .aboutbanner .container {max-width: 540px;}
  .about-page .aboutbanner h1 {font-size: 20px; line-height: 35px; padding-top: 50px;}
  .about-page .aboutmc h2 {font-size: 30px;}
  .about-page .aboutmc .content {font-size: 16px; line-height: 30px;}
  .about-page .aboutmc .content h3 {font-size: 20px;}
  .about-page .aboutourcoaches {padding-bottom: 70px;}
  .about-page .aboutourcoaches h1 {font-size: 30px;}
  .about-page .aboutourcoaches .content {font-size: 18px; line-height: 30px;}
  .about-page .aboutourcoaches .col-sm-1, .about-page .aboutourcoaches .col-sm-2 {flex: 0 0 100%; max-width: 100%;}
  .about-page .aboutourcoaches .image {margin: 0 0 10px 0;}
  .about-page .aboutourcoaches .name {margin-bottom: 30px;}
  .about-page .aboutstori h1 {font-size: 30px;}
  .about-page .aboutstori .image {margin-bottom: 30px;}
  .about-page .aboutstori .image img {width: 100%;}
  .about-page .aboutstori .content {font-size: 16px; line-height: 30px; margin: 0 0 30px 0;}
  .about-page .aboutpartner h1 {font-size: 27px;}
  .about-page .ourprices h2 {font-size: 20px;}
  .about-page .aboutpartner .col-sm-6 {flex: 0 0 100%; max-width: 100%;}
  .about-page .aboutpartner .prbox {margin-bottom: 30px;}
  .about-page .aboutpartner .prbox .primage img {width: 100%; height: 261px; object-fit: cover; object-position: center;}
  .about-page .aboutpartner .prbox .detail {padding: 30px 0 30px 20px;}
  .about-page .aboutreviews h1 {font-size: 30px;}
  .about-page .aboutleadership .image img {width: 100%; height: auto !important;}
  .about-page .aboutleadership h1 {font-size: 30px; margin-top: 30px;}
  .about-page .aboutadvisory h1 {font-size: 30px;}
  .about-page .aboutadvisory .col-sm-2 {flex: 0 0 100%; max-width: 100%;}
  .about-page .aboutadvisory .image {margin-bottom: 10px;}
  .about-page .aboutadvisory .name { margin-bottom: 30px;}
  .about-page .aboutresources h1 {font-size: 30px;}
  .about-page .aboutresources .col-sm-6 {flex: 0 0 100%; max-width: 100%;}
  .about-page .aboutresources .prbox .primage img {width: 100%; height: 261px; object-fit: cover; object-position: center;}
  .about-page .aboutresources .prbox .detail {padding: 30px 0 30px 20px;}
  .contact-page .contact-box .contact-detail {min-height: auto;}
  .contact-page .contact-box .contact-detail .info ul li {padding: 0 0px 0 0; font-size: 13px;}
  .contact-page .contact-box .contact-detail .info ul li a {font-size: 13px;}
  .contact-page .contact-box .contact-form {padding: 40px 20px;}
  .faqpage .faq-left {margin-bottom: 30px;}
  .faqpage .faq-right .fr-body .accordion .card .card-header .btn-link h4 span {position: absolute;right: 10px; top: 13px; margin: 0;}
  .content-page .contenttxt img {width: 100%;}
  .applytocoach .content p.ultxt img {float: left; margin: 5px 8px 8px 0;}
  .applytocoach .btndiv {text-align: center;}
  .applytocoach .btndiv .btn-primary {display: block; margin: 0 0px 10px 0;}
  .applytocoach .content {font-size: 14px;}
  .applytocoach .content .follows img {margin: 5px 8px 8px 0; float: left;}
  .applytoschool .content img {margin: 9px 25px 10px 0;}
  .applytoschool h2 img {margin: 5px 25px 20px 0; float: left;}
  .applytoschool .btndiv {text-align: center;}
  .applytoschool .btndiv .btn-primary {display: block; width: 100%; margin: 0 0 10px 0;}
  .register-page .login-box {padding: 20px; margin: 20px 0 20px auto;}
  .register-page .login-box .custom-radio {margin-bottom: 10px;}
  .register-page .login-box h1 {font-size: 28px;}
  .register-page iframe {transform:scale(0.82);-webkit-transform:scale(0.82);transform-origin:0 0;-webkit-transform-origin:0 0;}
  .login-page .login-box {padding: 20px 20px;}
  .login-page .login-box .forgot {text-align: left; padding-bottom: 30px;}
  .login-page.forgetPassword .login-box .backlogin {display: block; margin-top: 30px; margin-left: 0px;}
  .login-page.forgetPassword .login-box {padding-bottom: 20px;}
  .login-page.forgetPassword .login-box h1 {font-size: 28px;}
  .dashboard-layout {background: $secondary;}
  .dashboard-layout #left-side .dashlogo {text-align: center; padding-bottom: 80px;}
  .dashboard-layout .dashcontainer {max-width: 100%; margin: -265px auto 0 auto; padding-right: 15px; padding-left: 15px;}
  .dashboard-layout #left-side {width: 100%; margin: 0px 0px 0px 0;}
  .dashboard-layout #left-side .side-inner {width: 100%; min-height: auto; border-radius: 8px;}
  .dashboard-layout .aside-menu > li {width: 100%; float: left;}
  .dashboard-layout .aside-menu > li > a {padding: 0px 0px 0 10px;}
  .dashboard-layout .aside-menu > li > a:hover {background: $white;}
  .dashboard-layout .aside-menu > li > a.active:hover {background: #E9ECEF;}
  .dashboard-layout #right-side {width: 100%;}
  .dashboard-layout #right-side .dashheader .col-sm-2 {display: none;}
  .dashboard-layout #right-side .dashheader .col-sm-10 {position: absolute;top: 130px;margin: 0 auto;right: 0px;left: 0;width: 295px;}
  .dashboard-layout #right-side .dashheader .welcometext h1 {margin: 0px 0 25px 0; font-size: 25px;}
  .dashboard-layout #right-side .dashheader .welcometext {margin-bottom: 15px; margin-top: 15px}
  .card.custom .card-header {padding: 15px;}
  .card.custom .card-body {padding: 15px !important;}
  .dashboard-layout #right-side .dashheader .slidebtn {margin: 0 10px 0 0;}
  .dashboard-layout #right-side .dashheader a.findcoachlink {font-size: 14px;}
  .dashboard-layout #right-side .dashheader .usermenu img {width: 25px; vertical-align: text-top;}
  .rating-review-page .coach.media {display: block; text-align: center;}
  .rating-review-page .coach.media .image {margin: 0 0 15px 0;}
  .rating-review-page h3.coachtitle {text-align: center;}
  .rating-review-page .media .location img {margin: 0 7px 0 0; float: none;}
  .writeareview .rating-view ul.list-group {display: block;}
  .writeareview .rating-view ul.list-group li {display: block;width: 100%; margin: 15px 0 0 0; text-align: left;}
  .writeareview .rating-view ul.list-group li .smile-icon {margin-bottom: 0px;display: inline-block;margin-right: 10px;}
  .writeareview .rating-view ul.list-group li .rating-btn {display: inline-block;}
  .writeareview .rating-view ul.list-group li .rating-btn .btn-rate { text-align: left;}
  .writeareview .rating-view ul.list-group li .rating-btn .btn-rate span {display: inline-block; margin: 0 10px 0 0;}
  .rating-review-page .coach-profile {margin-bottom: 30px;}
  .booking-individual .messagesec .messages .mes-footer label {margin-right: 5px !important;}
  .booking-individual .messagesec .messages .mes-footer .form-control {width: 197px;}
  .booking-individual .messagesec .messages .msg-list ul li img {width: 100px;}
  .dashboard-layout #right-side {padding: 0px;}
  .card.custom .card-header .nav-link {margin: 0 25px 10px 0; font-size: 19px;}
  .booking-list .post-box {padding: 15px;}
  .booking-list .post-box .media {display: block; text-align: center;}
  .booking-list .post-box .image {margin: 0 0 15px 0;}
  .booking-list .post-box .location {margin-bottom: 5px;}
  .booking-list .post-box .location img {margin: 0 7px 0 0; float: none;}
  .booking-individual .coach.media {display: block; text-align: center; margin: 0 0 20px 0;}
  .booking-individual .coach.media .image {margin: 0 0 15px 0;}
  .booking-individual h3.coachtitle {text-align: center;}
  .booking-individual .media .location img {margin: 0 7px 0 0; float: none;}
  .booking-individual .inform .date {margin: 0px 0px 15px 0;}
  .booking-individual .badge {padding: 0 19px; font-size: 14px;}
  .booking-individual .inform {margin: 0 0 0 0;}
  .CP-individual .CP-detail-container .card-header {padding: 25px 15px !important;}
  .CP-individual .CP-detail-container .ratingreview-section .card-body .right-site {float: none;border-right: 0px solid #CCC;padding: 0 0 10px 0;width: 100%;text-align: center;border-bottom: 1px solid #CCC;}
  .CP-individual .CP-detail-container .ratingreview-section .card-body .right-site .image {float: none; margin: 0 0 15px 0;}
  .CP-individual .CP-detail-container .ratingreview-section .card-body .list-group-item {padding: 20px 15px;}
  .CP-individual .CP-detail-container .ratingreview-section .card-body .comment {padding: 15px 0 0 0px; float: none; width: 100% !important;}
  .CP-individual .CP-detail-container .ratingreview-section .card-body .comment .text {min-height: inherit;}
  .CP-individual .CP-detail-container .ratingreview-section {margin-bottom: 0;}
  .notifications .list-group-item {padding: 10px 15px;}
  .notifications .list-group-item h5 {margin: 12px 0 10px 0;}
  .notifications .list-group-item .bg-primary {width: 32px; height: 32px; line-height: 30px; float: right;}
  .notifications .list-group-item .bg-primary img {width: 17px;}
  .notifications .list-group-item p span {margin: 0 0 0 0px;}
  .notifications .list-group-item p {display: block; width: 100%;}
  .dashboard-layout #right-side .dashheader .available .switch .text-white.mr-1 {display: none;}
  .calendar-management .currentest {font-size: 15px; float: left; margin: -15px 0 10px 0;}
  .card.custom .card-header .plzeast {line-height: 22px; margin: 5px 0 10px 0; font-size: 15px;}
  .card.custom .card-header h1 {font-size: 19px; margin: 0 0 0px 0;}
  .card.custom .card-header h1 span {font-size: 15px; line-height: 30px;}
  .coach-profile.calendar-management .form-control {padding-left: 15px;}
  .coach-profile.calendar-management .MuiIconButton-root {margin-right: 0;}
  .calendar-management .action {text-align: center; padding: 10px 0 0 15px;}
  .experience .d-flex {display: block !important;}
  .coach-profile .experience .card-title.h5 .btn-outline-primary {float: none !important; margin: 25px 0 0 0;}
  .coach-profile .experience .list-group-item .button .bg-primary, .coach-profile .list-group-item .button .bg-secondary {margin: 0 0 0 10px; width: 32px; height: 32px; line-height: 30px;}
  .coach-profile .experience .list-group-item .button .bg-primary img {width: 17px;}
  .coach-profile .experience .list-group-item .button .bg-secondary img {width: 17px;}
  .coach-profile .experience .btn-info {margin: 0 0 0 10px; font-size: 14px;}
  .coach-profile .experience .btn-primary.two {padding: 0 10px; font-size: 14px;}
  .CP-individual .CP-detail-container .expertisesec .grading {height: auto; line-height: 20px; padding: 5px 15px;}
  .CP-individual .CP-detail-container .aboutcoach .content {line-height: 26px;}
  .CP-individual .CP-detail-container .ratingreview-section .rating {margin: 5px 0 0 0px; display: block;}
  .CP-individual .CP-detail-container .CP-detail {padding: 20px 15px 20px;}
  .CP-individual .CP-detail-container .CP-detail .image {text-align: center;}
  .CP-individual .CP-detail-container .CP-detail .detail-box {margin: 15px 0 0 0px; text-align: center;}
  .CP-individual .CP-detail-container .CP-detail .detail-box h4 {margin: 0 0px 15px 0;}
  .CP-individual .CP-detail-container .CP-detail .detail-box .location {margin: 0 0px 15px 0; display: block;}
  .CP-individual .CP-detail-container .CP-detail .detail-box .ypractice {margin: 15px 0 15px 0;}
  .CP-individual .CP-detail-container .CP-detail .btndiv {text-align: center;}
  .CP-individual .CP-detail-container .CP-detail .btndiv .btn-primary {width: auto; padding: 0 50px;}
  .CP-individual .CP-detail-container .CP-detail .btndiv .btn-warning {float: none !important; margin: 0 0 0 10px !important; vertical-align: baseline;}
  .findcoach .searchresult .coachbox {padding: 15px 0 0 0;}
  .findcoach .searchresult .coachbox .image {width: 100%;display: block;margin: 0 0 0 0; text-align: center;}
  .findcoach .searchresult .coachbox .image img {width: 190px; height: 190px; border-radius: 100% !important; border: 2px solid #ffffff; box-shadow: 0 0 6px #cfcfcf;}
  .findcoach .searchresult .coachbox .detail {display: block; padding: 15px 15px 60px 15px; width: auto; text-align: center; position: relative;}
  .findcoach .searchresult .coachbox .detail .rating {float: none; padding: 0 0 5px 0;}
  .findcoach .searchresult .coachbox .detail .location img {margin: 0 10px 0 0; float: none;}
  .findcoach .searchresult .coachbox .detail .btn-warning {position: absolute; float: none !important; top: 15px; right: 0;}
  .findcoach .searchresult .coachbox .detail .grading {width: 230px;}
  .coach-profile.schoolbooking .selectteacher {width: auto;margin: 15px 0 0 0;float: none;}
  .coach-profile.schoolbooking .nav .nav-item .nav-link {margin: 0 30px 0 0;}
  .coach-profile.schoolbooking .post-box .titlesec span {display: block;}
  .coach-profile.schoolbooking .post-box {padding: 30px 15px 10px 15px;}
  .coach-profile .profile-photo .notes ul {padding: 0 0 0 15px;}
  .MuiPaper-root.MuiDialog-paperScrollBody, .MuiDialog-root.zipcode-dialog .MuiPaper-root {min-width: auto !important;}
  .MuiPaper-root.MuiDialog-paperScrollBody .MuiDialogTitle-root, .MuiDialog-root.zipcode-dialog .MuiPaper-root .MuiDialogTitle-root {padding: 20px 15px 16px}
  .MuiPaper-root.MuiDialog-paperScrollBody .MuiTypography-h6, .MuiDialog-root.zipcode-dialog .MuiPaper-root .MuiTypography-h6 {font-size: 19px;}
  .MuiPaper-root.MuiDialog-paperScrollBody .MuiIconButton-root, .MuiDialog-root.zipcode-dialog .MuiPaper-root .MuiIconButton-root {font-size: 13px;width: 20px;height: 20px;}
  .MuiPaper-root.MuiDialog-paperScrollBody .MuiDialogContent-root, .MuiDialog-root.zipcode-dialog .MuiPaper-root .MuiDialogContent-root {padding: 30px 15px 5px 15px;}
  .MuiPaper-root.MuiDialog-paperScrollBody .input-group {display: block;}
  .MuiPaper-root.MuiDialog-paperScrollBody .input-group .form-control {width: 100%;}
  .MuiPaper-root.MuiDialog-paperScrollBody .btn-info, .MuiDialog-root.zipcode-dialog .MuiPaper-root .btn-info {margin: 10px 0 0 0px; line-height: 40px;}
  .MuiPaper-root.MuiDialog-paperScrollBody .btn-primary, .MuiDialog-root.zipcode-dialog .MuiPaper-root .btn-primary {padding: 0 52px;}
  .MuiDialog-paperWidthSm.MuiDialog-paperScrollBody {max-width: 100% !important;}
  .MuiDialog-paper {margin: 15px !important;}
  .card.custom .card-body .table th {white-space: nowrap;}
  .card.custom .card-body .table td {white-space: nowrap;}
  .coach-profile.packages.activepack .card.custom .card-header h1 {display: block !important;}
  .coach-profile.packages.activepack .card.custom .card-header h1 small {display: block; margin: 10px 0 0 0;}
  .user-search-list .media .image img {box-shadow: none;}
  .user-search-list .media h5 {margin: 0 0 15px 0;}
  .myteachers .add-new {margin: -27px 0 0 0;}
  .myteachers .add-new .btn-primary {padding: 0 20px; line-height: 35px;}
  .myteachers .post-box {padding: 25px 15px;}
  .myteachers .post-box .media {display: block !important;}
  .myteachers .post-box .image {margin: 0 0 15px 0; text-align: center;}
  .myteachers .post-box .media-body {text-align: center;}
  .myteachers .post-box .btndiv {margin: 15px 0 0px 0; text-align: center;}
  .myteachers .post-box .btndiv .btn-primary {margin: 3px 3px;}
  .coach-profile.scdashboard .dashmyteacher {padding: 30px 0 0 0; margin: 50px 0 0 0;}
  .content-page .contenttxt {white-space: break-spaces; word-break: break-word;}
  .about-page .ourprices .plansection .planbox {width: 50%;}
  .MuiDialog-root.zipcode-dialog.WelcomeDialog .MuiPaper-root .welcometxt p.semibold {font-size: 13px;}
  .invitationreceived .post-box {padding: 25px 15px;}
  .invitationreceived .post-box .media {display: block !important;}
  .invitationreceived .post-box .image {margin: 0 0 15px 0; text-align: center;}
  .invitationreceived .post-box .media-body {text-align: center;}
  .invitationreceived .post-box .btndiv {margin: 15px 0 0px 0; text-align: center;}
  .invitationreceived .post-box .btndiv .btn-primary {margin: 3px 3px;}
  .invitationreceived .post-box .invitdate {margin: 15px 0px 0 0; float: none;}
  .invitationreceived .post-box .invitdate span {margin: 0 0 0 0; display: block;}
  .datepicker .react-datepicker__day-name, .datepicker .react-datepicker__day, .datepicker .react-datepicker__time-name {width: 1.86rem;}
  .react-datepicker__month-container {float: none;}
  .datepicker .react-datepicker {width: 261px; margin: 0 auto; display: block;}
  .MuiPaper-root.MuiDialog-paperScrollBody .btntwo .btn-secondary {padding: 0 11px; margin: 0 3px;}
  .MuiPaper-root.MuiDialog-paperScrollBody .btntwo .btn-info {padding: 0 11px; margin: 0 3px; line-height: 48px !important;}
  .MuiDialog-root.bookcoach .est-current-time {font-size: 20px;}
  .MuiDialog-root.bookcoach .MuiTypography-h6.text-primary {font-size: 18px;}
  .booking-individual .messagesec {margin-top: 30px;}
}

.about-page .aboutadvisory .name {
  font-size: 18px;
  color: #2E2E2E;
  text-align: center;
  margin-bottom: 0px !important;
  font-weight: 700;
}

.about-page .aboutadvisory .post {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  text-align: center;
}

.team-div-outer { width: 188px; }




.starter-school-0 { color: rgb(245, 97, 255) !important; }
.price-school-0 { background-color: rgb(244, 188, 246) !important; color: rgb(255, 255, 255) !important;}
.session-scholl-0 { font-size: 15px !important;}

.starter-school-1 { color: rgb(79, 167, 255) !important; }
.price-school-1 { background-color: rgb(109, 159, 235) !important; color: rgb(255, 255, 255) !important; }
.session-scholl-1 { font-size: 15px !important;}

.starter-school-2 { color: rgb(205, 127, 50) !important; }
.price-school-2 { background-color: rgb(245, 178, 107) !important; color: rgb(255, 255, 255) !important;}
.session-school-2 { font-size: 15px !important;}

.starter-school-3 { color: rgb(184, 129, 57) !important; }
.price-school-3 { background-color: rgb(230, 145, 56) !important; color: rgb(255, 255, 255) !important;}
.session-school-3 { font-size: 15px !important;}


