
// Theme Color 
$primary:       #E44743 !default;
$secondary:     #084774 !default;
$success:       #169A48 !default;
$info:          #35BDCB!default;
$warning:       #FFBF00 !default;
$danger:        #c31818 !default;
$dark:          #272727 !default;
$white:         #ffffff !default;
$gray:          #bbbbbb !default;
$gray-100:      #f8f9fa !default;
$gray-200:      #e9ecef !default;
$gray-300:      #5E5E5E !default;
$gray-400:      #2c2c2b !default;
$gray-500:      #adb5bd !default;
$gray-600:      #2F363D !default;
$gray-700:      #495057 !default;
$gray-800:      #343a40 !default;
$gray-900:      #2E2E2E !default;
$black:         #000 !default;
$text:          #9198a8 !default;
$blue:          #182151 !default;
$purpul:        #8F10FF !default;
$yellow:        #D3DE25 !default;
//
$turquoise-blue:#71ebb2 !default;
$pink:          #f63869 !default;
$instagram:     #d93175 !default;
$google:     	#dc473a !default;
$facebook:      #3A559F !default;
$youtube:       #fe0000 !default;
$twitter:       #2DAAE1 !default;
$pinterest-p:   #cb2027 !default;
$behance:       #4176fa !default;
$dribbble:      #f26798 !default;
$linkedin:      #007ab9 !default;

$body-bg:       #020E2A !default;


$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $gray-200,
    "dark":       $dark,
    "white":      $white,
    "black":      $black,
    "facebook":   $facebook,
    "twitter":    $twitter,
);


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-shadows:                              false !default;
$enable-gradients:                            false !default;
$enable-hover-media-query:                    true !default;
$enable-responsive-font-sizes:                true !default;

