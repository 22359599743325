.css-scroll {scrollbar-color: $gray-300 $gray-200; scrollbar-width: thin;
	&::-webkit-scrollbar {width: 8px; background-color: $gray-300;} 
	&::-webkit-scrollbar-thumb {background-color: $dark; border-radius:10px;}
	&::-webkit-scrollbar-thumb:hover {background-color: $dark; border:1px solid $dark;}
	&::-webkit-scrollbar-thumb:active {background-color: $dark; border:1px solid $dark;} 
}
.padding-5 {margin-left: -5px; margin-right: -5px;
	[class*="col"] {padding-left: 5px; padding-right: 5px;}
}
.ladda-button[data-style=expand-right][data-loading] {padding-right: 56px !important;}

.lazy-load-image-background {width: 100% !important;}
.MuiSkeleton-root {background-color: rgba($black, 0.11) !important;}
.minH-250 {min-height: 250px;}
.share-button {text-align: left;}
.body-bg {background-color: $body-bg !important;}

.dropdown-notification {
	.notify-loading {display: flex; align-items: center; justify-content: center; min-height: 200px;}
    .scrolldiv .request {max-height: 220px; overflow-x: hidden; overflow-y: auto;}
    .dropdown-toggle {cursor: pointer; position: relative;
		&:after {content: none;}
		// img {transform: scale(1.4);}
		.badge {position: absolute;top: 6px; right: -3px;border-radius: 50%;width: 22px;height: 22px;justify-content: center;align-items: center;border: 2px solid white; font-size: 12px; font-weight: 400; font-family: Arial, Helvetica, sans-serif; line-height: 18px; text-align: center; padding: 0;}
	}
    .dropdown-menu {min-width: 330px; margin-top: 8px; border-radius: 2px; border: none; box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12); left: auto !important; right: 0px; position: absolute;
        .dropdown-arrow {position: absolute; bottom:100%; left: auto; right: -8px; overflow: hidden; height:22px; width:50px;
            &:after {content:''; transform: rotate(45deg); background: $white; position: absolute; top: 16px; right: 15px; width: 25px;height: 25px; border-radius: .25rem; box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);}
        }
        .dropdown-header {border-bottom: 1px solid rgba(0,0,0,.1); padding: 8px 0px 6px; line-height: 22px;
            .font-18 {font-size: 16px;}
        }
    }
    .timeline {position: relative; padding: 0; list-style: none; padding-left: 0px;
        .list-group {
			.list-group-item {padding: 5px; cursor: pointer; line-height: 18px;
				&:first-child {border-top: none;}
				&:hover {background-color: lighten($gray-300, 57%) !important;}
				&.active {background-color: lighten($gray-300, 57%) !important; border-color: rgba($black, 0.125) !important; color: inherit;}
				.image {margin-right: 6px; width: 40px; float: left;}
				.caption {width: calc(100% - 0px); font-size: 12px}
				.text {font-size: 11px; font-weight: 500;}
			}
		}
	}
	.scroller {max-height: 200px; min-height: 200px;}
    .scroll {height: 100%; max-height: 200px; overflow: auto;}
    @media(max-width: 600px) {
      .dropdown-menu {min-width: 310px; right: -133px;
        .dropdown-arrow {right: 128px;}
      }
    }
} // Notification dropdown css end

.google-location {position: relative;
	.autocomplete-dropdown {max-width: 300px; position: absolute; z-index: 9; min-width: 300px; left: 0px; top: 100%;}
	.spinner {padding: 5px 10px; background-color: $white;}
	.suggestion {padding: 8px 10px; cursor: pointer; background-color: $white; border-left: 1px solid $gray-100; border-right: 1px solid $gray-100;
		&.active  {background-color: $gray-100;}
		&:last-child {border-bottom: 1px solid $gray-100;}
	}
} // Google Location css end

.card-profile {
	.card-body {padding: 0px;}
	figure {margin-bottom: 20px;
		.pic {position: relative;
			img {height: 140px;}
		}
		.btn-upload {position: absolute; cursor: pointer; margin: 0px; left: 50%; bottom: -10px; font-size: 20px; padding: 0px 8px; margin-left: -20px; z-index: 9;
			input {width: 1px; height: 1px; visibility: hidden;}
		}
	}
} // Public Profile card css end


.loader {position: absolute; left: 0px; border-radius: 0%; width: 100%; height: 100%; display: flex; top: 0px; align-items: center; justify-content: center; background-color: rgba(0, 0, 0, 0.7);
    span { position: absolute; display: inline-block; width: 50px; height: 50px; border-radius: 100%; background:rgba(135, 211, 124,1); -webkit-animation: loader3 1.5s linear infinite; animation: loader3 1.5s linear infinite;
        &:last-child { animation-delay:-0.9s; -webkit-animation-delay:-0.9s;}
    }
 }
 @keyframes loader3 {
    0% {transform: scale(0, 0);opacity:0.8;}
    100% {transform: scale(1, 1);opacity:0;}
 }
 @-webkit-keyframes loader3 {
    0% {-webkit-transform: scale(0, 0);opacity:0.8;}
    100% {-webkit-transform: scale(1, 1);opacity:0;}
 } // Profile Loading css end

 .confirm-dialog {
	.MuiDialog-paperWidthSm {max-width: 400px; border-radius: 12px;}
	.MuiDialogTitle-root {padding: 10px 20px;}
	.MuiDialogContent-root {padding: 8px 20px;}
	.MuiDialogContentText-root {margin-bottom: 0px; font-weight: 600; color: $black;}
	.MuiTypography-h6 {font-weight: 600;}
	.btn {border-radius: 8px; font-size: 15px; padding: 6px 20px; font-weight: 500;}
	
}
.MuiDialog-paperWidthSm { min-width: 400px;
.MuiDialogTitle-root.heading { display: flex; justify-content: space-between; align-items: center;  }
}

// Confirm Dialog css end

.react-confirm-alert-overlay {background: rgba($black, 0.5);}
.react-confirm-alert-body {padding: 20px; font-size: 15px; font-weight: 600; color: $black;
	@media (max-width: 375px) {
		width: 305px;
	}
}
@media (max-width: 375px) {
	.react-confirm-alert-body > h1 {font-size: 20px !important;}
}
.react-confirm-alert-button-group {display: flex; align-items: center; justify-content: center;
	button {padding: 6px 26px; font-size: 14px; margin-right: 0px; margin-left: 10px; background-color: $gray-100; color: $black;
		&:last-child {background-color: $primary; color: $white;}
		&:first-child {background-color: $secondary; color: $white;}
	}
} // Corform alert

.datepicker {
	> div {width: 100%;}
	.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {width: 4.7rem; line-height: 2.7rem;}
	.react-datepicker {border-radius: 0px !important; border: none !important; width: 100%;
		.react-datepicker__header {background-color: transparent !important; border-bottom: none !important; padding-top: 0px !important; border-radius: 0px !important;
			.react-datepicker__current-month {background-color: #F6921E; color: $white; padding: 8px;}
			.react-datepicker__day-names {border-left: 1px solid $gray-200; border-right: 1px solid $gray-200;}
		}
		.react-datepicker__month {border: 1px solid $gray-200; margin: 0px; padding: 0.4rem;}
		.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range {background-color: #EC297B;
			&:hover {background-color: darken(#EC297B, 10%);}
			&:focus {outline: none;}
		}
	} 
} // Date picker css end

.upload-all {
	.image-list {list-style: none; padding-left: 0px; margin-bottom: 20px; margin-left: -5px; margin-right: -5px;
		li {padding: 0px 5px; margin-bottom: 10px;
			.box {position: relative;
				img {border: 1px solid $gray-400; border-radius: 8px;}
				.btn {position: absolute; color: $white; top: 10px; right: 10px;}
			}
		}
	}
	.image-box {margin-bottom: 15px; border-radius: 4px; position: relative;
		&:before {content: ""; position: absolute; width: 100%; height: 100%; background-color: rgba(0,0,0, 0.5)}
		.radio-tranparen {position: absolute; top: 4px; right: 20px; }
		.custom-radio .custom-control-label:before {border-radius: 4px;}
		.custom-control-label:before {background-color: rgba($white, 0.5); border: $white solid 1px;}
		.custom-radio .custom-control-input:checked ~ .custom-control-label:after {background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");}
		.btn-danger, .btn-info {position: absolute; top: 2px; right: 2px;}
	} // Upload image box css end
} // Common upload desig

.upload-box {position: relative;
	.action {height: 140px; background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='gray' stroke-width='4' stroke-dasharray='2%2c 4' stroke-dashoffset='8' stroke-linecap='butt'/%3e%3c/svg%3e");  border-radius: 8px; margin-bottom: 20px; position: relative;
		.select-file {position: relative;}
		input[type="file"] {position: absolute; left: 0px; top: 0px; width: 70px; opacity: 0; cursor: pointer; pointer-events: none;}
		label {cursor: pointer;}
		.upload-progress {position: absolute; width: 100%; bottom: 0px; left: 0px;}
		.spinner-border {position: absolute; right: 15px; top: 15px;}
		.onProgress {opacity: 0.6; pointer-events: none;}
	}
	.image-list {list-style: none; padding-left: 0px; margin-bottom: 20px; margin-left: -5px; margin-right: -5px;
		li {padding: 0px 5px; margin-bottom: 10px;
			.box {position: relative;
				img {border: 1px solid $gray-400; border-radius: 8px;}
				.btn {position: absolute; color: $white; top: 10px; right: 10px;}
			}
		}
	}
} // Upload Image box css end

.faddeble {background-color: rgba($white, 0.6); opacity: 0.5; pointer-events: none;}

.top-category {position: relative; z-index: 99;
	.app-tabs { min-height: 60px;		
			.nav-link {font: normal normal 800 18px/26px Mulish;padding: 0.5rem 2rem;}		
	 }
}

.dashboard-layout {padding: 0 0 50px 0 !important;
	&.bg-light {background-color: #F0F0F0 !important;}
	.dashcontainer {margin: -265px 0 0 0;}
	#left-side { 
		//width: 260px;
		width: 270px; margin: 0px 40px 0px 0; float: left; color: $primary; position: relative; transition: all 0.3s ease-in-out; border: 0px solid lighten($color: #000000, $amount: 78%);
		.side-inner {width: 270px; position: relative; background: $white; box-shadow: 0 0 6px #d6d6d6; padding: 30px 0; min-height: 670px;}
		.dashlogo {text-align: center; padding: 20px 0;
			img {width: 190px;}
		}
		.profile {padding: 0px 0px 35px 0; position: relative;
			.username {margin-top: 21px; text-align: center; display: block; font-size: 15px; font-weight: 600; color: $secondary; text-transform: uppercase;}
			.userrole {margin-top: 10px; font-size: 13px; color: $secondary; display: block; text-align: center;}
			.btn-toggle {float: right; transform: scale(1.9); padding: 0px 6px; color: lighten($color: #000000, $amount: 20%); position: absolute; right: 5px; top: 16px; transition: all 0.3s ease-in-out;background:transparent;border:none;outline:none;box-shadow:none;}
			.img {display: block; text-align: center;}
			img {width: 75px; height: 75px; object-fit: cover; object-position: center; border-radius: 100%; border: none !important;}
		}
		.heading {padding: 10px 15px; text-transform: uppercase; background-color: #292828; color: #BFBFBF; font-size: 13px; transition: all 0.3s ease-in-out;}

	}
	.aside-menu {list-style: none; margin: 0px; padding: 0px 15px;
		> li { width: auto; margin-bottom: 10px;
			> a { height: 40px; line-height: 40px; width: 100%; display: block; font-size: 15px;font-weight: 400; padding: 0px 0px 0 20px; border: 0px solid $white; text-transform: capitalize; color: #393939; ;position: relative; border-radius: 4px;
				&:hover, &.active {text-decoration: none; background-color: #E9ECEF; border: 0px solid $primary;}
					//border-top-left-radius: 15px;border-bottom-left-radius:15px;				
					.img {display: inline-block; text-align: center; margin: 0 10px 0px 0; width: 27px;
					img {padding: 0 0 0 0;}
					#count {background-color: $primary;color:$white;position:absolute;border:1px solid $primary;padding: 1px 4px;border-radius: 50%;font-size: 10px;margin-left: -13px;margin-top:5px;}
				}
				.name {vertical-align: middle;}
				.badge-primary {width: 30px;height: 30px;padding: 0;line-height: 28px;margin: 5px 5px 0 0px;float: right; border-radius: 50%; background: $white; border: 1px solid $secondary; color: $secondary;font-size: 14px;font-weight: 400;}
			}
		}
		li.profilelist {
			a {padding-top: 29px;}
		}
		li.notifications {
			a {padding-top: 35px;}
		}
		li.logout {
			a {padding-top: 52px;}
		}
	}
	#right-side {width: calc(96% - 270px); float: left; margin: 0px 0px 0px 0; transition: all 0.3s ease-in-out;
		.dashheader {padding: 35px 0 0 0;
			.slidebtn {display: inline-block; margin: 0 20px 0 0; vertical-align: top;
				button {padding: 0px; border: none; background: none;}
			}
			a.findcoachlink {font-size: 15px; text-transform: uppercase; font-weight: 600; color: $white;}
			.usermenu {text-align: right; margin: 0px 0 0 0;
				.message {margin-right: 18px; margin-left: 25px;}
				.notification {margin-right: 15px;
					.badge-primary {width: 20px;height: 20px;padding: 0;line-height: 20px;margin: -5px 0 0 -15px;position: absolute; border-radius: 50%; font-weight: 600;}
				}
				.setting.dropdown-toggle {background: none; border: none; padding: 0px; box-shadow: none;}
				.setting.dropdown-toggle::after {display: none;}
			}
			.available {margin: 0px 0 0 0;
				.title {font-size: 15px; color: $white; text-transform: uppercase; margin: 0 0 0 15px;}
				.MuiSwitch-root {padding: 0;width: 52px; height: 34px; border-radius: 18px;}
				.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {background-color: $white; opacity: 1;}
				.MuiSwitch-thumb {width: 22px; height: 22px;}
				.PrivateSwitchBase-root-1 {padding: 6px 4px;}
				.MuiSwitch-colorPrimary.Mui-checked {color: $secondary;}
			  }
			.welcometext {margin-bottom: 45px;
				h1 {font-size: 30px; font-weight: 600; margin: 35px 0 10px 0; color: $white;}
				.messagealert {font-size: 15px; color: $white;}
			}
		}
	}
	&.toggle-layout {transition: all 0.3s ease-in-out;
		#left-side {width: 72px; overflow: hidden;
			.side-inner {min-height: 724px; width: 72px}
			.dashlogo img {width: 70px;}
			.heading {height: 0px; padding: 0px;
				> span {opacity: 0;}
			}
			.profile {width: 72px;
				.username {display: none;}
				.btn-toggle {top: 20px; right: 228px;outline:none;box-shadow:none;}
				.img img {width: 55px; height: 55px;}
			}
			.aside-menu {padding: 0px 12px;}
			.aside-menu > li > a {font-size: 0px; padding: 0 10px;width: auto;display: inline-block;
				.name {display: none;}
			}
			.aside-menu > li > a .img {margin: 0px;}
			.badge-primary {width: 15px; height: 15px; line-height: 15px; margin: 0 0 0 -15px; font-size: 8px; position: absolute;}
		}
		#right-side {width: calc(92.7% - 70px);}
	}
} // Dashboard layout css end

@media (max-width: 767px) {
	.dashboard-layout {display: block;
		#left-side {display: block;}
		#right-side {display: block;width: 100%;padding: 10px;}
		&.toggle-layout {
			#left-side { width: 260px; 
				.profile {//width: 73px; 
					//.username {display: none;}
					.btn-toggle {top: 20px; right: 0px;outline:none;box-shadow:none;}
				}
			}
			#right-side {width: 100%;}
		}
	}
}

//category card
.category-card {
	.category-name { font: normal normal 900 22px/28px Mulish; color: $black; text-transform: uppercase; text-align: center; }
	.product-count { font: normal normal bold 18px/26px Mulish; color: $black;  text-transform: uppercase; text-align: center; }
}

//product card
.product-buttons { display: flex; justify-content: space-between; }

//section title and description
.section-title {  
	.title { font-weight: 900; font-size: 50px; color: $black; }
	.browse-text { p { font-weight: bold; font-size: 20px; color: $secondary; } }
}



//footer
footer#footer { 
	h5 { font: normal normal 900 18px/35px Mulish; color: $info; text-transform: uppercase; }
	.footer-menu { @include list-arrow($white);
		li {line-height: 36px;}
	}
	.navbar-social { flex-direction: row;  li { a { margin-right: 10px;color: lighten($color: #000000, $amount: 70%); } } }
	.copyright { color: lighten($color: #000000, $amount: 70%); font-size: 12px; }
 }

 //common form

	.category-card {
		h2 { 
			a {color: inherit;}
			a:hover {color: $primary; text-decoration: none;}
		}
		// .product-action-btn { 
		// 	button { padding: 7px 11px; font-size: 14px;
		// 			.MuiButton-label { align-items: end; }
		// 			} 
		// 					}
	
		}

// slick arrows starts
.slick-arrow { width: 50px; height: 44px; border: 1px solid lighten($color: #000000, $amount: 80%); top: -40px;background: white;
				&:after, &:before { color: $black; }
			}
.slick-prev { left: 94%; border-top-left-radius: 4px; border-bottom-left-radius: 4px; }
.slick-next { right: 4px; border-top-right-radius: 4px; border-bottom-right-radius: 4px; }
// slick arrows ends
// accordion plus minus
.accordion-main { box-shadow: none!important; border-bottom: 1px solid lighten($color: #000000, $amount: 80%);
	&.Mui-expanded { margin: 0!important; }
	> .Mui-expanded { border-bottom: 1px solid lighten($color: #000000, $amount: 80%); }
	.MuiAccordionSummary-root { padding: 0; }
}

//tabs
.nav-tabs { border-color: lighten($color: #000000, $amount: 80%);
	.nav-item { 
		.nav-link {  color: $black; text-transform: uppercase; border-radius: 0; border: 1px solid lighten($color: #000000, $amount: 80%); background-color: lighten($color: #000000, $amount: 93%); padding: 15px 30px; 
				&.active { background-color: $white; color: $warning; border-bottom: 1px solid $warning;}
		} 
	}
}

//account profile list start
.step-box {
	.list-group { border-radius: 0;
		a { color: lighten($color: #000000, $amount: 20%); font: normal normal bold 15px/19px Mulish; background-color: lighten($color: #000000, $amount: 96%); border-color: white;
			&.active { background-color: lighten($color: $primary, $amount: 30%); border-left: 5px solid $primary } 
		}
	 }
}
//account profile list end

//table starts
.dashed-table, .dashed-total-table {	border: 1px solid lighten($color: #000000, $amount: 82%);
	thead { background-color: lighten($color: #000000, $amount: 96%); border-bottom: 1px solid lighten($color: #000000, $amount: 82%);}
	tbody {tr{ td {border-bottom: 1px dashed lighten($color: #000000, $amount: 82%); vertical-align: middle;} }}
}
.dashed-total-table {		
	tbody {tr{td{border-right: 1px dashed lighten($color: #000000, $amount: 82%);border-left: 1px dashed lighten($color: #000000, $amount: 82%);}}}
}
//table ends
//card-header 
.card-header.main-heading { font: normal normal 800 25px/26px Mulish; }
//end
//banner-content-page
.about-banner { position: relative;
	.caption { position: absolute; top: 50%;left: 20%;transform: translate(0%, -50%);}

}

a { font-size: inherit; font-weight: inherit; color: inherit;
	&:hover{text-decoration: none;}
 }

// buy now single and checkout
.shopping-cart-buyer {	
		.accordion {
			.accordion-toggle:after { font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f068"; float: right; color: $black; }
			.accordion-toggle.collapsed:after { /* symbol for "collapsed" panels */ content: "\f067"; }
			> .card { border: none;
					>.card-header { background-color: #F1FFFA; padding: 15px 30px; border-top: 1px solid #CECDCD; font: normal normal bold 20px/26px Mulish; text-transform: uppercase;}
					.card-body { 
						p { font: normal normal bold 15px/26px Mulish;
							strong {font-weight: 900;}
						}
					}
				
				}
			}
			.payment-block {
				.payment-input-field { padding: 14px; }
			}
			table { td { font: normal normal 600 14px/25px Mulish; color: lighten($color: #000000, $amount: 60%);
				strong { color: lighten($color: #000000, $amount: 10%); }
			 } }
}
//review-list
.review-list {
	.list-group-item { position: relative;
		&:last-child { &::after { display: none; } }
		&:after {content: ""; background-color: lighten($color: #000000, $amount: 80%); width: 100%; height: 1px;position: absolute;bottom: 10px;}
	 }
	.right-site {
		.text-name { font: normal normal bold 18px/26px Mulish; text-transform: uppercase; }
		.text-date { font: normal normal 600 15px/26px Mulish; text-transform: uppercase; color: lighten($color: $black, $amount: 60%); }
		.comment {.text { font: italic normal normal 15px/28px Mulish; }}
	}
}

//for all lablel color
.MuiFormLabel-root {color: rgba(0, 0, 0, 1)!important;}

// for input underline
.MuiInput-underline:before{ border-bottom: none!important; }

.green-badge-div { width: 94.5px; height: 90.53px; bottom: 0px;}

.green-badge-div .first-div 
{ 
	font-weight: 600;
    font-size: 26px;
    line-height: 24px;
    padding-top: 12px; 
}

.green-badge-div .second-div 
{ 
	font-weight: 500;
    line-height: 21px; 
}

.green-badge-div .third-div 
{ 
	font-weight: 500;
}

.green-badge-cont
{
	margin-top: -25px;
    right: 40px;
}
